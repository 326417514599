import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Zone } from '@/redux/zone/zone.types.ts';

const zoneSliceInitialState = {
  zones: [],
  zone: { id: '', name: '', deviceId: '', parcelId: '', waterConsumption: [] },
  zoneId: '',
};

const zoneSlice = createSlice({
  name: 'zoneSlice',
  initialState: zoneSliceInitialState,
  reducers: {
    setZones: (state, action: PayloadAction<Zone[]>) => {
      state.zones = action.payload;
    },
    setZone: (state, action: PayloadAction<Zone>) => {
      state.zone = action.payload;
      state.zoneId = action.payload.id;
    },
  },
});

export const { setZones, setZone } = zoneSlice.actions;
export default zoneSlice.reducer;
