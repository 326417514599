import { FC, ReactNode, useEffect, useState } from 'react';

import { Card, CardContent } from '@/components/ui/card.tsx';

type CardProps = {
  sensorName: string;
  value: string;
  icon: ReactNode;
  deviceName: string;
  lastSync: string;
};

const SensorReadingCard: FC<CardProps> = ({
  sensorName,
  value,
  icon,
  deviceName,
  lastSync,
}) => {
  const [date, setDate] = useState<string>('');
  const [message, setMessage] = useState<string>('');

  useEffect(() => {
    if (lastSync.includes('|')) {
      const [first, second] = lastSync.split('|');
      setDate(first.trim());
      setMessage(second.trim());

      return;
    }

    setDate(lastSync);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastSync]);

  return (
    <Card>
      <CardContent>
        <div className="flex flex-row pt-6 w-[200px] sm:w-[360px]">
          {icon}
          <div className="flex flex-col">
            <p className="font-black text-[20px]">{value}</p>
            <p className="font-semibold text-gray-700 text-[14px]">
              {sensorName}
            </p>
            <p className="font-normal text-gray-500 text-[13px]">
              {deviceName}
            </p>
            <p className="font-normal text-gray-400 text-[12px] mt-2">
              Poslednje ažuriranje: {date}
            </p>
            {message ? (
              <p className="font-semibold text-red-500 text-[13px] mt-1">
                {message}
              </p>
            ) : null}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
export default SensorReadingCard;
