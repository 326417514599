import { z } from 'zod';

import i18n from '@/i18n.ts';

export const createLoginSchema = () => {
  return z.object({
    email: z.string().email({
      message: i18n.t('invalidEmail'),
    }),
    password: z.string().min(1, {
      message: i18n.t('mandatoryPassword'),
    }),
  });
};

export const createForgotPasswordSchema = () => {
  return z.object({
    email: z.string().email({
      message: i18n.t('invalidEmail'),
    }),
  });
};

export const createResetPasswordSchema = () => {
  return z
    .object({
      token: z.string().min(1, i18n.t('mandatoryToken')),
      password: z
        .string()
        .min(8, i18n.t('minPasswordLength'))
        .max(100, i18n.t('maxPasswordLength'))
        .refine((value) => /[a-z]/.test(value), i18n.t('passwordLowercase'))
        .refine((value) => /[A-Z]/.test(value), i18n.t('passwordUppercase'))
        .refine((value) => /\d/.test(value), i18n.t('passwordNumber'))
        .refine(
          (value) => /[!@#$%^&*(),.?":{}|<>]/.test(value),
          i18n.t('passwordSpecialChar')
        ),
      confirmPassword: z.string().min(1, i18n.t('mandatoryConfirmPassword')),
    })
    .refine((data) => data.password === data.confirmPassword, {
      message: i18n.t('passwordMismatch'),
      path: ['confirmPassword'],
    });
};

const calculateTotalShiftWorkTime = (shifts) => {
  return shifts.reduce(
    (sum, shift) => sum + (shift.workTimeOfIrrigation || 0),
    0
  );
};

export const createIrrigationFormSchema = () => {
  const basicIrrigationSchema = z.object({
    parcelId: z.string().min(1, i18n.t('mandatoryParcelId')),
    irrigationType: z.preprocess(
      (val) => (val === '' ? undefined : val),
      z.enum(['time', 'm3'], {
        required_error: i18n.t('mandatoryIrrigationType'),
      })
    ),
    dateAndTimeOfIrrigation: z
      .date()
      .min(
        new Date(Date.now() - 5 * 60 * 1000),
        'Navodnjavanje ne može početi u prošlosti'
      ),
    workTimeOfIrrigation: z.coerce.number().optional(),
    shifts: z.array(
      z
        .object({
          shiftId: z.coerce.number(),
          zone: z.string(),
          waterLevel: z.coerce.number().optional().optional(),
          workTimeOfIrrigation: z.coerce.number().optional().optional(),
        })
        .refine((shift) => shift.zone !== '', {
          path: ['shifts'],
          message: i18n.t('mandatoryShift'),
        })
    ),
    mixerEnabled: z.boolean(),
    mixerStartDateTime: z.date().optional(),
    mixerWorkTime: z.coerce.number().optional(),
    supplementOneEnabled: z.boolean(),
    supplementOneDateTime: z.date().optional(),
    supplementOneWorkTime: z.coerce.number().optional(),
    pH: z.coerce.number().optional(),
    supplementTwoEnabled: z.boolean(),
    supplementTwoDateTime: z.date().optional(),
    supplementTwoWorkTime: z.coerce.number().optional(),
    supplementTwoEC: z.coerce.number().optional(),
    supplementThreeEnabled: z.boolean(),
    supplementThreeDateTime: z.date().optional(),
    supplementThreeWorkTime: z.coerce.number().optional(),
    supplementThreeEC: z.coerce.number().optional(),
    supplementFourEnabled: z.boolean(),
    supplementFourDateTime: z.date().optional(),
    supplementFourWorkTime: z.coerce.number().optional(),
    supplementFourEC: z.coerce.number().optional(),
    programEnabled: z.boolean(),
    programName: z.coerce.string().optional(),
  });

  const mixerWorkTimeRefine = basicIrrigationSchema.refine(
    (data) => {
      if (data.mixerEnabled) {
        return data.mixerWorkTime > 0;
      }
      return true;
    },
    {
      path: ['mixerWorkTime'],
      message: '"Trajanje u minutima" mora biti pozitivan broj',
    }
  );

  const mixerEndTimeRefine = mixerWorkTimeRefine.refine(
    (data) => {
      if (data.mixerEnabled && data.irrigationType === 'time') {
        return (
          data.mixerStartDateTime.getTime() + data.mixerWorkTime * 60000 <=
          data.dateAndTimeOfIrrigation.getTime() +
            calculateTotalShiftWorkTime(data.shifts) * 60000
        );
      }
      return true;
    },
    {
      path: ['mixerStartDateTime'],
      message: '"Mešač" mora završiti sa radom pre kraja navodnjavanja',
    }
  );

  const supplementOneStartTimeRefine = mixerEndTimeRefine.refine(
    (data) => {
      if (data.supplementOneEnabled) {
        return data.supplementOneDateTime >= data.dateAndTimeOfIrrigation;
      }
      return true;
    },
    {
      path: ['supplementOneDateTime'],
      message: '"Prihrana 1" ne može početi pre navodnjavanja',
    }
  );

  const supplementOneWorkTimeRefine = supplementOneStartTimeRefine.refine(
    (data) => {
      if (data.supplementOneEnabled) {
        return data.supplementOneWorkTime > 0;
      }
      return true;
    },
    {
      path: ['supplementOneWorkTime'],
      message: '"Trajanje u minutima" mora biti pozitivan broj',
    }
  );

  const supplementOneDurationTimeRefine = supplementOneWorkTimeRefine.refine(
    (data) => {
      if (data.supplementOneEnabled) {
        return (
          data.supplementOneDateTime.getTime() +
            data.supplementOneWorkTime * 60000 <=
          data.dateAndTimeOfIrrigation.getTime() +
            calculateTotalShiftWorkTime(data.shifts) * 60000
        );
      }
      return true;
    },
    {
      path: ['supplementOneWorkTime'],
      message:
        '"Prihrana 1" se mora završiti najkasnije kad i samo navodnjavanje',
    }
  );

  const supplementOnePhRefine = supplementOneDurationTimeRefine.refine(
    (data) => {
      if (data.supplementOneEnabled) {
        return data.pH > 0;
      }
      return true;
    },
    {
      path: ['pH'],
      message: '"PH" vrednost mora biti pozitivan broj',
    }
  );

  const supplementTwoStartTimeRefine = supplementOnePhRefine.refine(
    (data) => {
      if (data.supplementTwoEnabled) {
        return data.supplementTwoDateTime >= data.dateAndTimeOfIrrigation;
      }
      return true;
    },
    {
      path: ['supplementTwoDateTime'],
      message: '"Prihrana 2" ne može početi pre navodnjavanja',
    }
  );

  const supplementTwoWorkTimeRefine = supplementTwoStartTimeRefine.refine(
    (data) => {
      if (data.supplementTwoEnabled) {
        return data.supplementTwoWorkTime > 0;
      }
      return true;
    },
    {
      path: ['supplementTwoWorkTime'],
      message: '"Trajanje u minutima" mora biti pozitivan broj',
    }
  );

  const supplementTwoDurationTimeRefine = supplementTwoWorkTimeRefine.refine(
    (data) => {
      if (data.supplementTwoEnabled) {
        return (
          data.supplementTwoDateTime.getTime() +
            data.supplementTwoWorkTime * 60000 <=
          data.dateAndTimeOfIrrigation.getTime() +
            calculateTotalShiftWorkTime(data.shifts) * 60000
        );
      }
      return true;
    },
    {
      path: ['supplementTwoWorkTime'],
      message:
        '"Prihrana 2" se mora završiti najkasnije kad i samo navodnjavanje',
    }
  );

  const supplementTwoEcRefine = supplementTwoDurationTimeRefine.refine(
    (data) => {
      if (data.supplementTwoEnabled) {
        return data.supplementTwoEC > 0;
      }
      return true;
    },
    {
      path: ['supplementTwoEC'],
      message: '"EC" vrednost mora biti pozitivan broj',
    }
  );

  const supplementThreeStartTimeRefine = supplementTwoEcRefine.refine(
    (data) => {
      if (data.supplementThreeEnabled) {
        return data.supplementThreeDateTime >= data.dateAndTimeOfIrrigation;
      }
      return true;
    },
    {
      path: ['supplementThreeDateTime'],
      message: '"Prihrana 3" ne može početi pre navodnjavanja',
    }
  );

  const supplementThreeWorkTimeRefine = supplementThreeStartTimeRefine.refine(
    (data) => {
      if (data.supplementThreeEnabled) {
        return data.supplementThreeWorkTime > 0;
      }
      return true;
    },
    {
      path: ['supplementThreeWorkTime'],
      message: '"Trajanje u minutima" mora biti pozitivan broj',
    }
  );

  const supplementThreeDurationTimeRefine =
    supplementThreeWorkTimeRefine.refine(
      (data) => {
        if (data.supplementThreeEnabled) {
          return (
            data.supplementThreeDateTime.getTime() +
              data.supplementThreeWorkTime * 60000 <=
            data.dateAndTimeOfIrrigation.getTime() +
              calculateTotalShiftWorkTime(data.shifts) * 60000
          );
        }
        return true;
      },
      {
        path: ['supplementThreeWorkTime'],
        message:
          '"Prihrana 3" se mora završiti najkasnije kad i samo navodnjavanje',
      }
    );

  const supplementThreeEcRefine = supplementThreeDurationTimeRefine.refine(
    (data) => {
      if (data.supplementThreeEnabled) {
        return data.supplementThreeEC > 0;
      }
      return true;
    },
    {
      path: ['supplementThreeEC'],
      message: '"EC" vrednost mora biti pozitivan broj',
    }
  );

  const supplementFourStartTimeRefine = supplementThreeEcRefine.refine(
    (data) => {
      if (data.supplementFourEnabled) {
        return data.supplementFourDateTime >= data.dateAndTimeOfIrrigation;
      }
      return true;
    },
    {
      path: ['supplementFourDateTime'],
      message: '"Prihrana 4" ne može početi pre navodnjavanja',
    }
  );

  const supplementFourWorkTimeRefine = supplementFourStartTimeRefine.refine(
    (data) => {
      if (data.supplementFourEnabled) {
        return data.supplementFourWorkTime > 0;
      }
      return true;
    },
    {
      path: ['supplementFourWorkTime'],
      message: '"Trajanje u minutima" mora biti pozitivan broj',
    }
  );

  const supplementFourDurationTimeRefine = supplementFourWorkTimeRefine.refine(
    (data) => {
      if (data.supplementFourEnabled) {
        return (
          data.supplementFourDateTime.getTime() +
            data.supplementFourWorkTime * 60000 <=
          data.dateAndTimeOfIrrigation.getTime() +
            calculateTotalShiftWorkTime(data.shifts) * 60000
        );
      }
      return true;
    },
    {
      path: ['supplementFourWorkTime'],
      message:
        '"Prihrana 4" se mora završiti najkasnije kad i samo navodnjavanje',
    }
  );

  const supplementFourEcRefine = supplementFourDurationTimeRefine.refine(
    (data) => {
      if (data.supplementFourEnabled) {
        return data.supplementFourEC > 0;
      }
      return true;
    },
    {
      path: ['supplementFourEC'],
      message: '"EC" vrednost mora biti pozitivan broj',
    }
  );

  return supplementFourEcRefine.refine(
    (data) => {
      if (data.programEnabled) {
        return data.programName !== '';
      }
      return true;
    },
    {
      path: ['programName'],
      message: 'Morate uneti ime programa',
    }
  );
};

export const createEditParcelSchema = () => {
  return z.object({
    id: z.string().min(1),
    name: z.string().min(1, {
      message: i18n.t('mandatoryParcelName'),
    }),
    numberOfZones: z.number().refine((val) => val > 0, {
      message: i18n.t('numberOfZonesValidation'),
    }),
    place: z.string().min(1, {
      message: i18n.t('mandatoryPlace'),
    }),
    country: z.string().min(1, {
      message: i18n.t('mandatoryCountry'),
    }),
    weatherForecast: z.string().min(1, {
      message: i18n.t('mandatoryWeatherForecast'),
    }),
    durationOfTheActiveValve: z
      .string()
      .refine((val) => !Number.isNaN(parseInt(val, 10)), {
        message: i18n.t('durationOfTheActiveValveValidation'),
      }),
    timeBetweenValves: z
      .string()
      .refine((val) => !Number.isNaN(parseInt(val, 10)), {
        message: i18n.t('timeBetweenValvesValidation'),
      }),
  });
};

export const createEditDeviceSchema = () => {
  return z.object({
    id: z.string().min(1),
    name: z.string().min(1, {
      message: i18n.t('mandatoryDeviceName'),
    }),
    color: z.string().min(1, {
      message: i18n.t('colorMandatory'),
    }),
  });
};

export const createEditSensorSchema = () => {
  return z.object({
    id: z.string().min(1),
    soilHumidity: z.boolean(),
    airHumidity: z.boolean(),
    airTemperature: z.boolean(),
    soilTemperature: z.boolean(),
    waterPH: z.boolean(),
    soilPH: z.boolean(),
    waterSalinity: z.boolean(),
    soilSalinity: z.boolean(),
    flowMeter: z.boolean(),
    waterLevel: z.boolean(),
    batteryLevel: z.boolean(),
    supplementOne: z.boolean(),
    supplementTwo: z.boolean(),
    supplementThree: z.boolean(),
    supplementFour: z.boolean(),
    supplementFive: z.boolean(),
    supplementMixer: z.boolean(),
    pidRegulatorOne: z.boolean(),
    pidRegulatorTwo: z.boolean(),
    pidRegulatorThree: z.boolean(),
    pidRegulatorFour: z.boolean(),
    proportionalMode: z.boolean(),
  });
};
