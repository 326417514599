import QuestionAndAnswer from '@/components/layout/contactForm/QuestionAndAnswer.tsx';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';

const Faq = () => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Najčešća pitanja</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col gap-5">
          <QuestionAndAnswer
            question="Koliko različitih programa navodnjavanja mogu da podesim?"
            answer1="U aplikaciji se može podesiti neograničen broj različitih programa i režima navodnjavanja."
          />
          <QuestionAndAnswer
            question="Šta će se desiti ako zakazem navodnjavanje a u tom momentu kontroler nema pristup Internetu?"
            answer1="U tom slučaju aplikacija će otkazati zakazano navodnjavanje, a vi ćete biti obavešteni zašto je navodnjavanje otkazano i možete zakazati novo navodnjavanje."
          />
          <QuestionAndAnswer
            question="Šta znači ako u aplikaciji piše 'Vaš kontroler nije povezan na Internet'?"
            answer1="Ovo znači da iz nekog razloga kontroler nije povezan na internet. Postoji mogućnost da je ugašen ili da usled lošeg signala i nedostupnosti mreže nije uspeo da se poveže na Internet."
          />
          <QuestionAndAnswer
            question="Sa koliko različitih uređaja mogu da pristupim kontroleru?"
            answer1="Aplikaciji se može pristupiti sa više različitih uređaja u isto vreme koristeći isti korisnički nalog."
          />
          <QuestionAndAnswer
            question="Sa koje lokacije se prikupljaju podaci o vremenskoj prognozi?"
            answer1="Podaci o vremenskoj prognozi se prikupljaju sa lokalnih meteoroloških stanica koje su najbliže mestu postavke kontrolera."
          />
          <QuestionAndAnswer
            question="Do kog datuma će trajati ponavljanje na svaka 24h, 48h, 72h i 7 dana?"
            answer1="Ponavljanje navodnjavanja će se prema programu ponavljati do momenta dok ne otkažete prvo sledeće zakazano navodnjavanje. To znači ako ste imali navodnjavanje danas, a sledeće je zakazano za 24h i ako otkažete to navodnjavanje otkazali ste i sva buduća."
            answer2="NAPOMENA: Voditi računa da se navodnjavanje na vreme otkaže da se pumpa ne bi uključila u zimskom periodu ili u vreme kada nema potrebe za navodnjavanjem."
          />
        </div>
      </CardContent>
    </Card>
  );
};
export default Faq;
