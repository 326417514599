import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import EditParcelForm from '@/components/forms/EditParcelForm.tsx';
import PageHeader from '@/components/layout/PageHeader.tsx';
import SelectedParcelHeader from '@/components/layout/SelectedParcelHeader.tsx';
import Sidebar from '@/components/layout/Sidebar.tsx';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import { getParcelByIdForEdit } from '@/redux/parcel/parcel.actions.ts';
import { getSelectedParcelId } from '@/redux/parcel/parcel.selectors.ts';
import {
  GetParcelForEditResponse,
  ParcelForUserEdit,
} from '@/redux/parcel/parcel.types.ts';

const EditParcelPage: FC = () => {
  const { t } = useTranslation('translation');
  const parcelId = useAppSelector(getSelectedParcelId);
  const [parcelForEdit, setParcelForEdit] = useState<ParcelForUserEdit>({
    id: '',
    name: '',
    numberOfZones: 0,
    place: '',
    country: '',
    weatherForecast: '',
    additionalSensorsValues: {
      numberOfIrrigation: false,
      airHumidity: false,
      pumpSensor: false,
      numberOfZones: false,
      airTemperature: false,
      soilTemperatureSensor: false,
      soilSalinity: false,
      flowMeter: false,
      waterLevelSensor: false,
      pHSensor: false,
      flowMeterCapacity: 0,
      flowMeterFactorK: 0,
      flowMeterFactorM: 0,
      pHFactorK: 0,
      minHumidityForAutomaticIrrigation: 0,
      optimalHumidityForAutomaticIrrigation: 0,
      durationOfTheActiveValve: 0,
      timeBetweenValves: 0,
      moreSENSSensors: false,
    },
    basicSensorsValues: {
      humiditySensor: false,
      rawSensorValues: false,
      secondHumiditySensor: false,
      supplement: false,
      supplementMixer: false,
      voltage: false,
    },
  });
  const dispatch = useAppDispatch();
  const router = useNavigate();

  const handleErrorResponse = (response: GetParcelForEditResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  useEffect(() => {
    const fetchParcel = async () => {
      // @ts-ignore
      const response = await dispatch(getParcelByIdForEdit(parcelId)).unwrap();
      if (!response.success) {
        handleErrorResponse(response);
        return;
      }

      setParcelForEdit(response.content);
    };

    if (!parcelId) {
      return;
    }
    fetchParcel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parcelId]);

  return (
    <div className="flex min-h-screen w-full flex-row bg-muted/40">
      <Sidebar />
      <div className="flex flex-col sm:gap-4 sm:py-4 w-full">
        <PageHeader shouldShowInput={false} />
        <div className="grid items-start gap-4 sm:px-6 p-4 sm:py-0 md:gap-8">
          <SelectedParcelHeader showNewIcon />
          <Card x-chunk="edit-parcel-cart">
            <CardHeader className="flex flex-row justify-between items-center">
              <CardTitle>{t('changeParcel')}</CardTitle>
            </CardHeader>
            <CardContent>
              <EditParcelForm existingParcel={parcelForEdit} />
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default EditParcelPage;
