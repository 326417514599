import { zodResolver } from '@hookform/resolvers/zod';
import { AccordionContent } from '@radix-ui/react-accordion';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import CustomFormSwitch from '@/components/controls/CustomFormSwitch.tsx';
import EditSupplementSensorForm from '@/components/forms/EditSupplementSensorForm.tsx';
import CreateButtonSection from '@/components/forms/shared/CreateButtonSection.tsx';
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion.tsx';
import { Form } from '@/components/ui/form.tsx';
import { getBreadcrumbs } from '@/redux/breadcrumbs/breadcrumbs.selectors.ts';
import { useAppSelector } from '@/redux/hooks.ts';
import { ParcelSensorValuesForEdit } from '@/redux/parcel/parcel.types.ts';
import { createEditSensorFormStartData } from '@/utils/sensors.ts';
import { createEditSensorSchema } from '@/zod/types.ts';

type EditSensorFormProps = {
  sensorValuesForEdit: ParcelSensorValuesForEdit;
};

const EditSensorForm: FC<EditSensorFormProps> = ({ sensorValuesForEdit }) => {
  const { t } = useTranslation('translation');
  const router = useNavigate();
  const breadcrumbs = useAppSelector(getBreadcrumbs);
  const [activeAccordion, setActiveAccordion] =
    useState<string>('basicSensorData');

  const editForm = useForm({
    resolver: zodResolver(createEditSensorSchema()),
    defaultValues: { ...createEditSensorFormStartData() },
  });

  function fillEditForm(sensorValues: ParcelSensorValuesForEdit) {
    editForm.setValue(
      'soilHumidity',
      sensorValues.basicSensorValues.humiditySensor
    );
    editForm.setValue(
      'airHumidity',
      sensorValues.additionalSensorValues.airHumidity
    );
    editForm.setValue(
      'airTemperature',
      sensorValues.additionalSensorValues.airTemperature
    );
    editForm.setValue(
      'soilTemperature',
      sensorValues.additionalSensorValues.soilTemperatureSensor
    );
    editForm.setValue('soilPH', sensorValues.additionalSensorValues.pHSensor);
    editForm.setValue(
      'soilSalinity',
      sensorValues.additionalSensorValues.soilSalinity
    );
    editForm.setValue(
      'flowMeter',
      sensorValues.additionalSensorValues.flowMeter
    );

    editForm.setValue(
      'waterLevel',
      sensorValues.additionalSensorValues.waterLevelSensor
    );
    editForm.setValue('waterSalinity', false);
    editForm.setValue('waterPH', false);
    editForm.setValue('batteryLevel', false);
    editForm.setValue('supplementOne', false);
    editForm.setValue('supplementTwo', false);
    editForm.setValue('supplementThree', false);
    editForm.setValue('supplementFour', false);
    editForm.setValue('supplementFive', false);
    editForm.setValue('supplementMixer', false);
    editForm.setValue('proportionalMode', false);
    editForm.setValue('pidRegulatorOne', false);
    editForm.setValue('pidRegulatorTwo', false);
    editForm.setValue('pidRegulatorThree', false);
    editForm.setValue('pidRegulatorFour', false);
  }

  useEffect(() => {
    fillEditForm(sensorValuesForEdit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sensorValuesForEdit]);

  const onGiveUpClick = () => {
    const { link } = breadcrumbs[breadcrumbs.length - 2];
    router(link);
  };

  return (
    <Form
      reset={editForm.reset}
      formState={editForm.formState}
      clearErrors={editForm.clearErrors}
      control={editForm.control}
      getFieldState={editForm.getFieldState}
      getValues={editForm.getValues}
      handleSubmit={editForm.handleSubmit}
      register={editForm.register}
      resetField={editForm.resetField}
      setError={editForm.setError}
      setFocus={editForm.setFocus}
      setValue={editForm.setValue}
      trigger={editForm.trigger}
      unregister={editForm.unregister}
      watch={editForm.watch}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Accordion
          value={activeAccordion}
          onValueChange={(value) => setActiveAccordion(value || '')}
          type="single"
          collapsible
          className="w-full"
        >
          <AccordionItem value="basicSensorData">
            <AccordionTrigger>{t('sensors')}</AccordionTrigger>
            <AccordionContent>
              <CustomFormSwitch
                customForm={editForm}
                name="soilHumidity"
                labelText={t('soilHumidity')}
                formItemStyle="flex flex-col pt-5 max-md:py-5 gap-1"
                labelStyle="max-md:w-full text-sm"
              />
              <CustomFormSwitch
                customForm={editForm}
                name="airHumidity"
                labelText={t('airHumidity')}
                formItemStyle="flex flex-col pt-5 max-md:py-5 gap-1 sm:mt-3"
                labelStyle="max-md:w-full text-sm"
              />
              <CustomFormSwitch
                customForm={editForm}
                name="airTemperature"
                labelText={t('airTemperature')}
                formItemStyle="flex flex-col pt-5 max-md:py-5 gap-1 sm:mt-3"
                labelStyle="max-md:w-full text-sm"
              />
              <CustomFormSwitch
                customForm={editForm}
                name="soilTemperature"
                labelText={t('soilTemperature')}
                formItemStyle="flex flex-col pt-5 max-md:py-5 gap-1 sm:mt-3"
                labelStyle="max-md:w-full text-sm"
              />
              <CustomFormSwitch
                customForm={editForm}
                name="waterPH"
                labelText={t('waterPH')}
                formItemStyle="flex flex-col pt-5 max-md:py-5 gap-1 sm:mt-3"
                labelStyle="max-md:w-full text-sm"
              />
              <CustomFormSwitch
                customForm={editForm}
                name="soilPH"
                labelText={t('soilPH')}
                formItemStyle="flex flex-col pt-5 max-md:py-5 gap-1 sm:mt-3"
                labelStyle="max-md:w-full text-sm"
              />
              <CustomFormSwitch
                customForm={editForm}
                name="waterSalinity"
                labelText={t('waterSalinity')}
                formItemStyle="flex flex-col pt-5 max-md:py-5 gap-1 sm:mt-3"
                labelStyle="max-md:w-full text-sm"
              />
              <CustomFormSwitch
                customForm={editForm}
                name="soilSalinity"
                labelText={t('soilSalinity')}
                formItemStyle="flex flex-col pt-5 max-md:py-5 gap-1 sm:mt-3"
                labelStyle="max-md:w-full text-sm"
              />
              <CustomFormSwitch
                customForm={editForm}
                name="flowMeter"
                labelText={t('flowMeter')}
                formItemStyle="flex flex-col pt-5 max-md:py-5 gap-1 sm:mt-3"
                labelStyle="max-md:w-full text-sm"
              />
              <CustomFormSwitch
                customForm={editForm}
                name="waterLevel"
                labelText={t('waterLevel')}
                formItemStyle="flex flex-col pt-5 max-md:py-5 gap-1 sm:mt-3"
                labelStyle="max-md:w-full text-sm"
              />
              <CustomFormSwitch
                customForm={editForm}
                name="batteryLevel"
                labelText={t('batteryLevel')}
                formItemStyle="flex flex-col pt-5 max-md:py-5 gap-1 sm:mt-3 sm:mb-5"
                labelStyle="max-md:w-full text-sm"
              />
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="FERTSensorData">
            <AccordionTrigger>{t('supplement')}</AccordionTrigger>
            <AccordionContent>
              <EditSupplementSensorForm form={editForm} />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
        <CreateButtonSection
          onGiveUpClick={onGiveUpClick}
          submitText={t('save')}
        />
      </form>
    </Form>
  );
};

export default EditSensorForm;
