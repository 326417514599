import React, { FC, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/ui/form.tsx';
import { Input } from '@/components/ui/input.tsx';

type CustomFormInputProps = {
  form: UseFormReturn<any, any, any>;
  name: string;
  labelText: string;
  defaultValue: number;
  formItemStyle?: string;
  labelStyle?: string;
  inputStyle?: string;
};

const CustomFormSmallInput: FC<CustomFormInputProps> = ({
  form,
  name,
  labelText,
  defaultValue,
  formItemStyle,
  labelStyle,
  inputStyle,
}) => {
  const [inputValue, setInputValue] = useState(defaultValue);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    setInputValue(e.target.value);
  };

  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => {
        return (
          <FormItem className={formItemStyle}>
            <FormLabel className={labelStyle}>{labelText}</FormLabel>

            <FormControl className={inputStyle}>
              <Input
                {...field}
                value={inputValue}
                type="number"
                onChange={handleChange}
                min={0}
              />
            </FormControl>
          </FormItem>
        );
      }}
    />
  );
};

export default CustomFormSmallInput;
