import { ErrorResponse } from '@/redux/auth/auth.types.ts';

export type CreateIrrigationResponse = {
  success: boolean;
  message?: string;
  error?: ErrorResponse;
  content?: Irrigation;
};

export enum IrrigationType {
  M3 = 'm3',
  TIME = 'time',
}

export enum IrrigationStatus {
  SCHEDULED = 0,
  IN_PROGRESS = 1,
  COMPLETED = 2,
}

export type Shift = {
  shiftId: number;
  zone: number;
  waterLevel?: number;
  workTimeOfIrrigation?: number;
};

export type SupplementOneIrrigation = {
  startDateTime: Date;
  workTime: number;
  pH: number;
};

export type AdditionalSupplementIrrigation = {
  supplementIdx: number;
  startDateTime: Date;
  workTime: number;
  ec: number;
};

export type Irrigation = {
  id: string;
  parcelId: string;
  irrigationType: IrrigationType;
  dateAndTimeOfIrrigation: Date;
  workTimeOfIrrigation?: number;
  shifts?: Shift[];
  mixerStartDateTime?: Date;
  mixerWorkTime?: number;
  supplementOne?: SupplementOneIrrigation;
  additionalSupplements?: AdditionalSupplementIrrigation[];
  irrigationStatus: IrrigationStatus;
};

export type GetScheduledIrrigationResponse = {
  success: boolean;
  message?: string;
  error?: ErrorResponse;
  content?: Irrigation[];
};
