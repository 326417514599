import { FC, useEffect, useState } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import CustomFormCombobox, {
  ComboboxItemType,
} from '@/components/controls/CustomFormCombobox.tsx';
import CustomFormInput from '@/components/controls/CustomFormInput.tsx';
import { FormDescription } from '@/components/ui/form.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { getCitiesApi } from '@/redux/countries/countries.actions.ts';
import { CitiesResponse } from '@/redux/countries/countries.types.ts';
import { useAppDispatch } from '@/redux/hooks.ts';
import { countries } from '@/utils/countryUtil.ts';

type ParcelInfoFormProps = {
  form: UseFormReturn<any>;
};

const ParcelInfoForm: FC<ParcelInfoFormProps> = ({ form }) => {
  const { t } = useTranslation('translation');

  const countriesComboboxContent: ComboboxItemType[] = countries.map(
    (country) => {
      return {
        value: country,
        label: country,
      };
    }
  );

  const selectedCountry = useWatch({ control: form.control, name: 'country' });
  const [cities, setCities] = useState<ComboboxItemType[]>([]);
  const dispatch = useAppDispatch();
  const router = useNavigate();

  const handleErrorResponse = (response: CitiesResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  useEffect(() => {
    const fetchCities = async () => {
      const response = await dispatch(getCitiesApi(selectedCountry)).unwrap();

      if (!response.success) {
        handleErrorResponse(response);
      }

      setCities(response.content.map((city) => ({ value: city, label: city })));
    };

    if (selectedCountry) {
      fetchCities();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry]);

  return (
    <>
      <CustomFormInput
        customForm={form}
        name="name"
        labelText={t('parcelName')}
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="text"
      />
      <CustomFormInput
        customForm={form}
        name="numberOfZones"
        labelText={t('numberOfZones')}
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="number"
      />
      <FormDescription className="px-2">
        {t('numberOfZonesDescription')}
      </FormDescription>
      <CustomFormCombobox
        customForm={form}
        name="country"
        labelText={t('country')}
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full w-[300px]"
        buttonStyle="max-md:w-full md:w-[500px] px-3 py-2 justify-between"
        items={countriesComboboxContent}
        placeholder={t('chooseCountry')}
      />
      <CustomFormCombobox
        customForm={form}
        name="place"
        labelText="Mesto"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full w-[300px]"
        buttonStyle="max-md:w-full md:w-[500px] px-3 py-2 justify-between"
        items={cities}
        placeholder="Odaberite grad"
      />
      <CustomFormCombobox
        customForm={form}
        name="weatherForecast"
        labelText={t('weatherForecast')}
        formItemStyle="flex flex-col w-full pb-5 mt-2 sm:mt-5 px-2"
        labelStyle="max-sm:w-full w-[300px]"
        buttonStyle="max-md:w-full w-[500px] px-3 py-2 justify-between"
        items={cities}
        placeholder={t('chooseWeatherForecast')}
      />
    </>
  );
};

export default ParcelInfoForm;
