import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import CustomFormDropDown, {
  DropDownItems,
} from '@/components/controls/CustomFormDropDown.tsx';
import { Form } from '@/components/ui/form.tsx';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import {
  getParcelsDropdownUsers,
  getSelectedParcelId,
} from '@/redux/parcel/parcel.selectors.ts';
import { setParcel } from '@/redux/parcel/parcel.slice.ts';

export type ParcelFormData = {
  parcelId: string;
};
const SelectUserParcelForm: FC = () => {
  const parcelId = useAppSelector(getSelectedParcelId);
  const dispatch = useAppDispatch();
  const parcelForm = useForm({
    defaultValues: {
      parcelId,
    },
  });
  const userParcels = useAppSelector(getParcelsDropdownUsers);
  const parcelsDropdown: DropDownItems[] = userParcels.map((parcel) => ({
    text: parcel.name,
    value: parcel.id,
  }));

  const onChangeFunction = (selectedParcelId: string) => {
    const selectedParcel = userParcels.filter(
      (parcel) => parcel.id === selectedParcelId
    )[0];

    dispatch(setParcel(selectedParcel));
  };

  useEffect(() => {
    parcelForm.setValue('parcelId', parcelId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parcelId]);

  return (
    <Form
      reset={parcelForm.reset}
      formState={parcelForm.formState}
      clearErrors={parcelForm.clearErrors}
      control={parcelForm.control}
      getFieldState={parcelForm.getFieldState}
      getValues={parcelForm.getValues}
      handleSubmit={parcelForm.handleSubmit}
      register={parcelForm.register}
      resetField={parcelForm.resetField}
      setError={parcelForm.setError}
      setFocus={parcelForm.setFocus}
      setValue={parcelForm.setValue}
      trigger={parcelForm.trigger}
      unregister={parcelForm.unregister}
      watch={parcelForm.watch}
    >
      <form className="flex flex-col items-center justify-center md:w-[300px] lg:w-[400px] max-md:w-full mr-2">
        <CustomFormDropDown
          customForm={parcelForm}
          name="parcelId"
          labelText=""
          items={parcelsDropdown}
          onChangeFunction={onChangeFunction}
          formItemStyle="flex flex-col w-full"
        />
      </form>
    </Form>
  );
};

export default SelectUserParcelForm;
