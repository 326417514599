/* eslint-disable */
export const countries = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Brazil',
  'British Indian Ocean Territory',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Myanmar',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cape Verde',
  'Cayman Islands',
  'Central African Republic',
  'Chad',
  'Christmas Island',
  'Cocos (Keeling) Islands',
  'Cook Islands',
  'Chile',
  'China',
  'Colombia',
  'Comoros',
  'Congo',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Timor-Leste',
  'Tokelau',
  'Turks and Caicos Islands',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Ethiopia',
  'Faroe Islands',
  'Falkland Islands',
  'Fiji',
  'Finland',
  'France',
  'French Polynesia',
  'Gabon',
  'Gambia',
  'Georgia',
  'Gibraltar',
  'Germany',
  'Ghana',
  'Greece',
  'Greenland',
  'Guadeloupe',
  'Grenada',
  'Guernsey',
  'Guatemala',
  'Guam',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Heard Island and McDonald Islands',
  'Jordan',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Ivory Coast',
  'Jamaica',
  'Jersey',
  'Japan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'North Korea',
  'Kosovo',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Luxembourg',
  'Macedonia',
  'Madagascar',
  'Marshall Islands',
  'Macau',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Moldova',
  'Mongolia',
  'Montenegro',
  'Monaco',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Norway',
  'Niue',
  'Norfolk Island',
  'Northern Mariana Islands',
  'Oman',
  'Pakistan',
  'Pitcairn',
  'Palau',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'South Korea',
  'Lithuania',
  'Qatar',
  'Romania',
  'Russia',
  'Rwanda',
  'Réunion',
  'Samoa',
  'Saint Lucia',
  'San Marino',
  'Saint Kitts and Nevis',
  'Saint Pierre and Miquelon',
  'Saint Vincent and the Grenadines',
  'Saudi Arabia',
  'Senegal',
  'South Georgia and the South Sandwich Islands',
  'Sao Tome and Principe',
  'Sierra Leone',
  'Serbia',
  'Seychelles',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tanzania',
  'Thailand',
  'Togo',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Vatican City State (Holy See)',
  'Wallis and Futuna',
  'Venezuela',
  'Vietnam',
  'Yemen',
  'Zambia',
  'Zimbabwe',
];

export const cities = [
  'Beograd',
  'Novi Sad',
  'Niš',
  'Kragujevac',
  'Subotica',
  'Zrenjanin',
  'Pančevo',
  'Čačak',
  'Kraljevo',
  'Smederevo',
  'Leskovac',
  'Užice',
  'Valjevo',
  'Kruševac',
  'Vranje',
  'Šabac',
  'Sombor',
  'Pirot',
  'Zaječar',
  'Požarevac',
  'Bor',
  'Sremska Mitrovica',
  'Prokuplje',
  'Loznica',
  'Kikinda',
  'Vršac',
  'Jagodina',
  'Paraćin',
  'Negotin',
  'Bačka Palanka',
  'Vrbas',
  'Trstenik',
  'Lazarevac',
  'Inđija',
  'Ruma',
  'Aranđelovac',
  'Senta',
  'Stara Pazova',
  'Ćuprija',
  'Aleksinac',
  'Bečej',
  'Apatin',
  'Knjaževac',
  'Nova Pazova',
  'Bajina Bašta',
  'Mladenovac',
  'Lučani',
  'Bogatić',
  'Žabalj',
];
