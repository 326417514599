import SendQuestionForm from '@/components/forms/SendQuestionForm.tsx';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';

const SendQuestion = () => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Imate pitanje?</CardTitle>
      </CardHeader>
      <CardContent>
        <SendQuestionForm />
      </CardContent>
    </Card>
  );
};
export default SendQuestion;
