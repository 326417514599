import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import {
  FieldArray,
  FieldArrayMethodProps,
  FieldArrayWithId, UseFieldArrayRemove,
  UseFormReturn,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import CustomFormDropDown, {
  DropDownItems,
} from '@/components/controls/CustomFormDropDown.tsx';
import CustomFormInput from '@/components/controls/CustomFormInput.tsx';
import { IrrigationFormData } from '@/components/forms/CreateIrrigationForm.tsx';
import { Button } from '@/components/ui/button.tsx';
import { useAppSelector } from '@/redux/hooks.ts';
import { getSelectedParcelNumberOfZones } from '@/redux/parcel/parcel.selectors.ts';
import {
  getZonesForNumberOfZones,
  IrrigationType,
} from '@/utils/irrigation.utils.ts';

type ShiftsFormSectionProps = {
  form: UseFormReturn<any>;
  fields: FieldArrayWithId<IrrigationFormData, 'shifts', 'id'>[];
  append: (
    value:
      | FieldArray<IrrigationFormData, 'shifts'>
      | FieldArray<IrrigationFormData, 'shifts'>[],
    options?: FieldArrayMethodProps
  ) => void;
  remove: UseFieldArrayRemove;
};

type ShiftFormProps = {
  form: UseFormReturn;
  field: FieldArrayWithId<IrrigationFormData, 'shifts', 'id'>;
  index: number;
  zonesForDropdown: DropDownItems[];
  remove: UseFieldArrayRemove;
};

const generateId = (elements: number[] | null): number => {
  if (!elements || !elements.length) {
    return 1;
  }

  const elementWithHighestId = elements.reduce((maxElement, currentElement) => {
    return currentElement > maxElement ? currentElement : maxElement;
  }, elements[0]);
  return elementWithHighestId + 1;
};

const ShiftForm: FC<ShiftFormProps> = ({
  form,
  index,
  zonesForDropdown, remove

}) => {
  const { t } = useTranslation('translation');


  const itemsForDropdown = zonesForDropdown;

  return (
    <div className="flex flex-col">
      <span className="text-xl">
        {t('shift')} {index + 1}
      </span>
      <div className="flex flex-col md:flex-row gap-5 pt-3">
        <CustomFormDropDown
          customForm={form}
          name={`shifts.${index}.zone`}
          labelText={t('zone')}
          formItemStyle="w-[500px] flex flex-col max-md:w-full"
          labelStyle="max-md:w-full"
          items={itemsForDropdown}
        />
        {form.getValues('irrigationType') === IrrigationType.M3 && (
          <CustomFormInput
            customForm={form}
            name={`shifts.${index}.waterLevel`}
            labelText="Površina (u metrima kubnim)"
            formItemStyle="flex flex-col w-[500px] max-md:w-full"
            labelStyle="w-full"
            divStyle="w-full"
            type="number"
            inputStyle="py-[22px]"
          />
        )}
        {form.getValues('irrigationType') === IrrigationType.TIME && (
          <CustomFormInput
            customForm={form}
            name={`shifts.${index}.workTimeOfIrrigation`}
            labelText="Trajanje u minutima"
            formItemStyle="flex flex-col w-[500px] max-md:w-full"
            labelStyle="w-full"
            divStyle="w-full"
            type="number"
            inputStyle="py-[22px]"
          />
        )}
        <div className="w-[20px] items-end justify-center flex">
          <Button size="icon"  onClick={() => remove(index)} type="button">
            <FontAwesomeIcon
              icon={faTrash}
              color="red"
              className="w-full h-4"
            />
          </Button>
        </div>
      </div>
      <div>
        {form.getValues(`shifts.${index}.zone`) === '' && (
          <p className="'text-sm font-medium text-red-500 dark:text-red-900' break-words w-full m-0">
            Odaberite zonu
          </p>
        )}
        {form.getValues(`shifts.${index}.waterLevel`) <= 0 &&
          form.getValues('irrigationType') === IrrigationType.M3 && (
            <p className="'text-sm font-medium text-red-500 dark:text-red-900' break-words w-full m-0">
              <q>Površina (u metrima kubnim)</q> mora biti pozitivan broj
            </p>
          )}
        {form.getValues(`shifts.${index}.workTimeOfIrrigation`) <= 0 &&
          form.getValues('irrigationType') === IrrigationType.TIME && (
            <p className="'text-sm font-medium text-red-500 dark:text-red-900' break-words w-full m-0">
              <q>Trajanje u minutima</q> mora biti pozitivan broj
            </p>
          )}
      </div>
    </div>
  );
};

const ShiftsFormSection: FC<ShiftsFormSectionProps> = ({
  form,
  fields,
  append,
  remove,
}) => {
  const { t } = useTranslation('translation');
  const parcelNumberOfZones = useAppSelector(getSelectedParcelNumberOfZones);
  const zonesForDropdown = getZonesForNumberOfZones(parcelNumberOfZones);

  const shiftIds = fields.map((field) => field.shiftId);


  const onAddShiftClick = () => {
    if (fields.length === parcelNumberOfZones) {
      toast.info(t('maxNumberOfShifts'));
      return;
    }

    append({
      shiftId: generateId(shiftIds),
      zone: 0,
      waterLevel: 0,
      workTimeOfIrrigation: 0,
    });
  };

  return (
    <div className="flex flex-col gap-5 pb-5 max-md:py-5 pt-2">
      {fields.map((field, index) => (
        <ShiftForm
          key={`shift-for-irrigation-creation-${field.id}`}
          form={form}
          field={field}
          index={index}
          zonesForDropdown={zonesForDropdown}
          remove={remove}
        />
      ))}
      <Button
        className="bg-[#33b35a] py-3 px-2 text-white w-[120px]"
        type="button"
        onClick={onAddShiftClick}
      >
        {t('addShift')}
      </Button>
    </div>
  );
};

export default ShiftsFormSection;
