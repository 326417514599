import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import CustomFormSmallInput from '@/components/controls/CustomFormSmallInput.tsx';
import CustomFormSwitch from '@/components/controls/CustomFormSwitch.tsx';

type EditSupplementSensorFormProps = {
  form: UseFormReturn<any>;
  switchLabelName: string;
  firstInputLabelName: string;
  secondInputLabelName: string;
  thirdInputLabelName: string;
  showAdditionalOptions: boolean;
};

const EditSupplementFactorsForm: FC<EditSupplementSensorFormProps> = ({
  form,
  switchLabelName,
  firstInputLabelName,
  secondInputLabelName,
  thirdInputLabelName,
  showAdditionalOptions,
}) => {
  const { t } = useTranslation('translation');
  // @ts-ignore
  return (
    <div className="ml-6">
      <div className="flex flex-row max-md:flex-col w-full pb-5 max-md:py-0 md:mt-2">
        <CustomFormSwitch
          customForm={form}
          name={switchLabelName}
          labelText={t('pidRegulator')}
          formItemStyle="flex flex-col pt-1 max-md:py-2 gap-1 sm:mt-2"
          labelStyle="max-md:w-full md:w-[250px] text-sm "
        />
        <div className="flex flex-row gap-4">
          <CustomFormSmallInput
            form={form}
            name={firstInputLabelName}
            labelText="Kp"
            defaultValue={5}
            formItemStyle="flex flex-col pt-1 max-md:py-2 gap-0.5 sm:mt-2"
            labelStyle="max-md:w-full text-sm"
            inputStyle="max-w-16 focus:outline-none h-9"
          />
          <CustomFormSmallInput
            form={form}
            name={secondInputLabelName}
            labelText="Ki"
            defaultValue={3}
            formItemStyle="flex flex-col pt-1 max-md:py-2 gap-0.5 sm:mt-2"
            labelStyle="max-md:w-full text-sm"
            inputStyle="max-w-16 focus:outline-none h-9"
          />
          <CustomFormSmallInput
            form={form}
            name={thirdInputLabelName}
            labelText="Kd"
            defaultValue={2}
            formItemStyle="flex flex-col pt-1 max-md:py-2 gap-0.5 sm:mt-2"
            labelStyle="max-md:w-full text-sm"
            inputStyle="max-w-16 focus:outline-none h-9"
          />
        </div>
      </div>
      {showAdditionalOptions && (
        <>
          <CustomFormSwitch
            customForm={form}
            name="proportionalModeForSupplementFour"
            labelText={t('proportionalModeForSupplement')}
            formItemStyle="flex flex-col pt-1 max-md:py-2 gap-1"
            labelStyle="w-full text-sm "
          />
          <CustomFormSwitch
            customForm={form}
            name="flowMeterForSupplementFour"
            labelText={t('flowMeter')}
            formItemStyle="flex flex-col pt-1 max-md:py-2 gap-1 sm:mt-4"
            labelStyle="w-full text-sm "
          />
        </>
      )}
    </div>
  );
};

export default EditSupplementFactorsForm;
