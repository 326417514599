import { FC } from 'react';

import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';

type QuestionAndAnswerProps = {
  question: string;
  answer1: string;
  answer2?: string;
};

const QuestionAndAnswer: FC<QuestionAndAnswerProps> = ({
  question,
  answer1,
  answer2,
}) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>{question}</CardTitle>
      </CardHeader>
      <CardContent className="whitespace-pre-line flex flex-col">
        <p>{answer1}</p>
        {answer2 ? <p className="mt-2">{answer2}</p> : null}
      </CardContent>
    </Card>
  );
};
export default QuestionAndAnswer;
