import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faPlay, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import ConfirmDialog from '@/components/forms/program/ConfirmDialog.tsx';
import { Button } from '@/components/ui/button.tsx';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import { IrrigationType } from '@/redux/irrigation/irrigation.types.ts';
import { getSelectedParcelId } from '@/redux/parcel/parcel.selectors.ts';
import {
  deleteSavedProgram,
  getProgramsForParcel,
} from '@/redux/program/program.actions.ts';
import { getPrograms } from '@/redux/program/program.selectors.ts';
import {
  CreateProgramResponse,
  GetProgramsResponse,
  Program,
} from '@/redux/program/program.types.ts';

type SingleProgramProps = {
  program: Program;
  setIsDialogOpen: (value: ((prevState: boolean) => boolean) | boolean) => void;
};

type SavedProgramProps = {
  program: Program;
};

const ZoneProgram: FC<SingleProgramProps> = ({ program, setIsDialogOpen }) => {
  const { t } = useTranslation('translation');

  return (
    <div className="w-full bg-white flex flex-row justify-between px-2 py-2 max-md:flex-col rounded-lg shadow-[0px_0px_6px_0px_rgba(0,_0,_0,_0.1)] mt-2">
      <div className="flex flex-col md:min-w-[225px]">
        <p className="text-lg font-bold">{program.name}</p>
        {program.shifts.map((shift) => (
          <span
            className="text-sm"
            key={`irrigation-${program.id}-shift-${shift.shiftId}`}
          >
            {`${t('zone')} ${shift.zone}`}
            {': '}
            {`${shift.waterLevel} metara kubnih`}
          </span>
        ))}
      </div>
      <div className="md:border-l md:border-l-[##868e96] md:pr-40 flex flex-col justify-center">
        <div className="flex flex-row max-md:gap-5 max-md: items-center justify-center">
          <Button className="bg-white flex flex-row gap-1 text-green-600 md:ml-5 text-sm">
            <FontAwesomeIcon icon={faPlay} />
            {t('schedule')}
          </Button>
          <Button
            className="bg-white flex flex-row gap-1 text-red-500 md:ml-5 text-sm"
            onClick={() => setIsDialogOpen(true)}
          >
            <FontAwesomeIcon icon={faTimes} />
            {t('delete')}
          </Button>
        </div>
      </div>
    </div>
  );
};

const TimeProgram: FC<SingleProgramProps> = ({ program, setIsDialogOpen }) => {
  const { t } = useTranslation('translation');

  return (
    <div className="w-full bg-white flex flex-row justify-between px-2 py-2 max-md:flex-col rounded-lg shadow-[0px_0px_6px_0px_rgba(0,_0,_0,_0.1)] mt-2">
      <div className="flex flex-col md:min-w-[225px]">
        <p className="text-lg font-bold">{program.name}</p>
        <p className="text-sm font-light flex flex-row gap-2 items-center">
          <FontAwesomeIcon icon={faClock} />
          {`${program.workTimeOfIrrigation} min`}
        </p>
        {program.shifts.map((shift) => (
          <span
            className="text-sm"
            key={`irrigation-${program.id}-shift-${shift.shiftId}`}
          >
            {`${t('zone')} ${shift.zone}`}
            {': '}
            {`${shift.workTimeOfIrrigation} minuta`}
          </span>
        ))}
      </div>
      <div className="md:border-l md:border-l-[##868e96] md:pr-40 flex flex-col justify-center">
        <div className="flex flex-row max-md:gap-5 max-md: items-center justify-center">
          <Button className="bg-white flex flex-row gap-1 text-green-600 md:ml-5 text-sm">
            <FontAwesomeIcon icon={faPlay} />
            {t('schedule')}
          </Button>
          <Button
            className="bg-white flex flex-row gap-1 text-red-500 md:ml-5 text-sm"
            onClick={() => setIsDialogOpen(true)}
          >
            <FontAwesomeIcon icon={faTimes} />
            {t('delete')}
          </Button>
        </div>
      </div>
    </div>
  );
};

const SavedProgram: FC<SavedProgramProps> = ({ program }) => {
  const { t } = useTranslation('translation');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const dispatch = useAppDispatch();
  const router = useNavigate();

  const onDiscardClick = () => {
    setIsDialogOpen(false);
  };

  const handleErrorResponse = (response: CreateProgramResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      localStorage.removeItem('id');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error('Error while fetching parcels for user');
  };
  const onContinueClick = async () => {
    // @ts-ignore
    const result = await dispatch(deleteSavedProgram(program.id)).unwrap();

    if (!result.success) {
      handleErrorResponse(result);
      return;
    }

    toast.success(t('successfulProgramDeletion'));
    setIsDialogOpen(false);
  };

  return (
    <>
      {program.irrigationType === IrrigationType.M3 && (
        <ZoneProgram program={program} setIsDialogOpen={setIsDialogOpen} />
      )}
      {program.irrigationType === IrrigationType.TIME && (
        <TimeProgram program={program} setIsDialogOpen={setIsDialogOpen} />
      )}
      <ConfirmDialog
        headerText={t('deleteProgram')}
        descriptionText={t('deleteProgramDesc')}
        onDiscardClick={onDiscardClick}
        onContinueClick={onContinueClick}
        setIsDialogOpen={setIsDialogOpen}
        isDialogOpen={isDialogOpen}
        confirmText={t('yes')}
        discardText={t('no')}
        confirmStyle="bg-[#dc3545] py-3 px-5 text-white"
      />
    </>
  );
};

const SavedProgramsSection: FC = () => {
  const parcelId = useAppSelector(getSelectedParcelId);
  const programs = useAppSelector(getPrograms);
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const { t } = useTranslation('translation');

  const handleErrorResponse = (response: GetProgramsResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      localStorage.removeItem('id');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error('Error while fetching saved programs');
  };

  useEffect(() => {
    if (!parcelId) {
      return;
    }
    const fetchSavedPrograms = async () => {
      // @ts-ignore
      const response = await dispatch(getProgramsForParcel(parcelId)).unwrap();

      if (!response.success) {
        handleErrorResponse(response);
      }
    };

    fetchSavedPrograms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parcelId]);

  return (
    <Card className="">
      <CardHeader>
        <CardTitle className="flex items-center w-full py-3">
          {t('savedPrograms')}
        </CardTitle>
      </CardHeader>
      <CardContent>
        {programs.map((program, index) => (
          <SavedProgram
            program={program}
            key={`${program.id}-saved-program-${index + 1}`}
          />
        ))}
      </CardContent>
    </Card>
  );
};

export default SavedProgramsSection;
