import { FC, ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { routeToPage } from '@/redux/breadcrumbs/breadcrumbs.slice.ts';
import { BreadcrumbLinkType } from '@/redux/breadcrumbs/breadcrumbs.types.ts';
import { useAppDispatch } from '@/redux/hooks.ts';

type RoutesMiddlewareProps = {
  children: ReactNode;
};

const RoutesMiddleware: FC<RoutesMiddlewareProps> = ({ children }) => {
  const { t } = useTranslation('translation');
  const location = useLocation();
  const dispatch = useAppDispatch();
  const generateBreadcrumb = (
    link: string,
    linkText: string,
    localeId: string
  ) => {
    const breadcrumbLink: BreadcrumbLinkType = {
      link,
      linkText,
      localeId,
      showSeparator: false,
    };
    dispatch(routeToPage(breadcrumbLink));
  };

  const routeConfig = {
    '/irrigation': {
      entity: t('irrigation'),
      localeId: 'irrigation',
      addContent: {
        localeId: 'scheduleIrrigation',
        text: t('scheduleIrrigation'),
      },
    },
    '/settings': {
      entity: t('settings'),
      localeId: 'settings',
    },
    '/parcel-settings': {
      entity: t('parcelSettings'),
      localeId: 'parcelSettings',
    },
    '/device-settings': {
      entity: t('deviceSettings'),
      localeId: 'deviceSettings',
    },
    '/change-device': {
      entity: t('changeDevice'),
      localeId: 'changeDevice',
    },
    '/sensor-settings': {
      entity: t('sensorSettings'),
      localeId: 'sensorSettings',
    },
    '/contact-form': {
      entity: 'Kontakt forma',
      localeId: 'contactForm',
    },
  };

  const handleRoutes = (path: string) => {
    const config = Object.keys(routeConfig).find((route) =>
      path.startsWith(route)
    );

    if (!config) {
      generateBreadcrumb('/', `${t('home')}`, 'home');
      return;
    }

    const { entity, localeId, addContent } = routeConfig[config];
    if (path.includes('/edit')) {
      generateBreadcrumb(path, 'test text', 'test');
      return;
    }
    if (path.includes('/create')) {
      generateBreadcrumb(path, addContent.text, addContent.localeId);
      return;
    }

    generateBreadcrumb(path, entity, localeId);
  };

  useEffect(() => {
    handleRoutes(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return children;
};

export default RoutesMiddleware;
