import {
  CircleAlert,
  List,
  MessagesSquare,
  Scale,
  Settings,
  SlidersHorizontal,
  Unplug,
} from 'lucide-react';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';

type SettingsPageCardProps = {
  icon: ReactNode;
  title: string;
  description: string;
  link: string;
};

const SettingsPageCard: FC<SettingsPageCardProps> = ({
  icon,
  title,
  description,
  link,
}) => {
  const router = useNavigate();

  const onCardClick = () => {
    router(link);
  };

  return (
    <Card
      className="hover:shadow-[0px_0px_6px_0px_rgba(0,_0,_0,_0.1)] cursor-pointer hover:text-[#28a745] text-gray-500"
      onClick={onCardClick}
    >
      <CardHeader className="flex items-center justify-center p-0 pt-6">
        {icon}
      </CardHeader>
      <CardContent>
        <CardTitle className="text-center">{title}</CardTitle>
        <p className="text-sm  text-center mt-2">{description}</p>
      </CardContent>
    </Card>
  );
};

const SettingsPageContent: FC = () => {
  const { t } = useTranslation('translation');

  return (
    <div className="pt-5">
      <h1 className="text-2xl font-bold mb-6">{t('settings')}</h1>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-3 xl:grid-cols-4">
        <SettingsPageCard
          icon={<Settings className="w-8 h-8" />}
          title={t('parcelSettings')}
          description={t('parcelSettingsDescription')}
          link="/parcel-settings"
        />
        <SettingsPageCard
          icon={<SlidersHorizontal className="w-8 h-8" />}
          title={t('sensorCalibration')}
          description={t('sensorCalibrationDescription')}
          link="/"
        />
        <SettingsPageCard
          icon={<CircleAlert className="w-8 h-8" />}
          title={t('sensorSettings')}
          description={t('sensorSettingsDescription')}
          link="/sensor-settings"
        />
        <SettingsPageCard
          icon={<Scale className="w-8 h-8" />}
          title={t('autoModeSettings')}
          description={t('autoModeSettingsDescription')}
          link="/"
        />
        <SettingsPageCard
          icon={<List className="w-8 h-8" />}
          title={t('zoneSettings')}
          description={t('zoneSettingsDescription')}
          link="/"
        />
        <SettingsPageCard
          icon={<Unplug className="w-8 h-8" />}
          title={t('deviceSettings')}
          description={t('deviceSettingsDescription')}
          link="/device-settings"
        />
        <SettingsPageCard
          icon={<MessagesSquare className="w-8 h-8" />}
          title={t('notificationSettings')}
          description={t('notificationSettingsDescription')}
          link="/"
        />
      </div>
    </div>
  );
};
export default SettingsPageContent;
