import { FC, useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import CustomFormSwitch from '@/components/controls/CustomFormSwitch.tsx';
import EditSupplementFactorsForm from '@/components/forms/EditSupplementFactorsForm.tsx';

type EditSupplementSensorFormProps = {
  form: UseFormReturn<any>;
};

const EditSupplementSensorForm: FC<EditSupplementSensorFormProps> = ({
  form,
}) => {
  const { t } = useTranslation('translation');

  const [proportionalMode, setProportionalMode] = useState<boolean>(
    form.getValues('proportionalMode')
  );

  useEffect(() => {
    setProportionalMode(form.getValues('proportionalMode'));
    form.setValue('supplementFour', form.getValues('proportionalMode'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.watch('proportionalMode')]);

  return (
    <div className="mb-5">
      <CustomFormSwitch
        customForm={form}
        name="supplementOne"
        labelText={t('supplementOne')}
        formItemStyle="flex flex-col pt-5 max-md:py-5 gap-1"
        labelStyle="max-md:w-full text-sm"
      />
      {form.watch('supplementOne') && (
        <EditSupplementFactorsForm
          form={form}
          switchLabelName="pidRegulatorOne"
          firstInputLabelName="kpFactorOne"
          secondInputLabelName="kiFactorOne"
          thirdInputLabelName="kdFactorOne"
          showAdditionalOptions={false}
        />
      )}
      <CustomFormSwitch
        customForm={form}
        name="supplementTwo"
        labelText={t('supplementTwo')}
        formItemStyle="flex flex-col pt-5 max-md:py-5 gap-1 sm:mt-3"
        labelStyle="max-md:w-full text-sm"
      />
      {form.watch('supplementTwo') && (
        <EditSupplementFactorsForm
          form={form}
          switchLabelName="pidRegulatorTwo"
          firstInputLabelName="kpFactorTwo"
          secondInputLabelName="kiFactorTwo"
          thirdInputLabelName="kdFactorTwo"
          showAdditionalOptions={false}
        />
      )}
      <CustomFormSwitch
        customForm={form}
        name="supplementThree"
        labelText={t('supplementThree')}
        formItemStyle="flex flex-col pt-5 max-md:py-5 gap-1 sm:mt-3"
        labelStyle="max-md:w-full text-sm"
      />
      {form.watch('supplementThree') && (
        <EditSupplementFactorsForm
          form={form}
          switchLabelName="pidRegulatorThree"
          firstInputLabelName="kpFactorThree"
          secondInputLabelName="kiFactorThree"
          thirdInputLabelName="kdFactorThree"
          showAdditionalOptions={false}
        />
      )}
      <CustomFormSwitch
        customForm={form}
        name="supplementFour"
        labelText={t('supplementFour')}
        formItemStyle="flex flex-col pt-5 max-md:py-5 gap-1 sm:mt-3"
        labelStyle="max-md:w-full text-sm"
      />
      {form.watch('supplementFour') && (
        <EditSupplementFactorsForm
          form={form}
          switchLabelName="pidRegulatorFour"
          firstInputLabelName="kpFactorFour"
          secondInputLabelName="kiFactorFour"
          thirdInputLabelName="kdFactorFour"
          showAdditionalOptions={proportionalMode}
        />
      )}
      <CustomFormSwitch
        customForm={form}
        name="supplementFive"
        labelText={t('supplementFive')}
        formItemStyle="flex flex-col pt-5 max-md:py-5 gap-1 sm:mt-3"
        labelStyle="max-md:w-full text-sm"
      />
      <CustomFormSwitch
        customForm={form}
        name="supplementMixer"
        labelText={t('supplementMixer')}
        formItemStyle="flex flex-col pt-5 max-md:py-5 gap-1 sm:mt-3"
        labelStyle="max-md:w-full text-sm"
      />
      <CustomFormSwitch
        customForm={form}
        name="proportionalMode"
        labelText={t('proportionalMode')}
        formItemStyle="flex flex-col pt-5 max-md:py-5 gap-1 sm:mt-3"
        labelStyle="max-md:w-full text-sm whitespace-pre-line"
      />
    </div>
  );
};

export default EditSupplementSensorForm;
