import {
  CloudRain,
  Droplet,
  Droplets,
  Gauge,
  Leaf,
  Sun,
  TestTube,
  Thermometer,
  Waves,
  Wind,
} from 'lucide-react';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import SensorReadingCard from '@/components/layout/statistics/SensorReadingCard.tsx';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { getDeviceById } from '@/redux/device/device.actions.ts';
import {
  Device,
  DeviceType,
  GetDeviceByIdResponse,
} from '@/redux/device/device.types.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import { getSensorReadingsForZone } from '@/redux/zone/zone.actions.ts';
import { getZone } from '@/redux/zone/zone.selectors.ts';
import {
  FertDevicesSensors,
  GetSensorReadingsResponse as ZoneReadingResponse,
  SensDevicesSensors,
  StartDevicesSensors,
} from '@/redux/zone/zone.types.ts';
import { formatDateLastUpdated } from '@/utils/dateUtil.ts';

type CardGroupsStartProps = {
  response: StartDevicesSensors;
  device: Device;
};

type CardGroupsFertProps = {
  response: FertDevicesSensors;
  device: Device;
};

type CardGroupsSensProps = {
  response: SensDevicesSensors;
  device: Device;
};

type CardData = {
  reading: number | string;
  name: string;
  sync: string;
};

const CollectionOfSensorReadingCardsForStartDevice: FC<
  CardGroupsStartProps
> = ({ response, device }) => {
  const [humidityOne, setHumidityOne] = useState<CardData>({
    reading: 0,
    name: 'Uredjaj',
    sync: '15',
  });
  const [pressureInSystemStart, setPressureInSystemStart] = useState<CardData>({
    reading: 0,
    name: 'Uredjaj',
    sync: '15',
  });
  const [soilTemperature, setSoilTemperature] = useState<CardData>({
    reading: 0,
    name: 'Uredjaj',
    sync: '15',
  });
  const [flowMeterStart, setFlowMeterStart] = useState<CardData>({
    reading: 0,
    name: 'Uredjaj',
    sync: '15',
  });

  useEffect(() => {
    if (!response) {
      return;
    }

    setHumidityOne({
      reading: response.humidityValue,
      name: device.name,
      sync: device.syncTime,
    });
    setPressureInSystemStart({
      reading: response.pressureInSystemValue,
      name: device.name,
      sync: device.syncTime,
    });
    setFlowMeterStart({
      reading: response.flowMeterValue,
      name: device.name,
      sync: device.syncTime,
    });

    setSoilTemperature({
      reading: parseFloat((Math.random() * 40).toFixed(1)),
      name: 'Uredjaj',
      sync: '15',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  return (
    <div className="flex flex-row gap-5 flex-wrap">
      <SensorReadingCard
        sensorName="VLAŽNOST ZEMLJIŠTA"
        value={`${humidityOne.reading}%`}
        icon={<Droplets className="h-10 w-10 mr-2 mt-1 text-[#0077b6]" />}
        deviceName={humidityOne.name}
        lastSync={formatDateLastUpdated(humidityOne.sync)}
      />
      <SensorReadingCard
        sensorName="TEMPERATURA ZEMLJIŠTA"
        value={`${soilTemperature.reading} °C`}
        icon={<Thermometer className="h-10 w-10 mr-2 mt-1 text-[#8d6e63]" />}
        deviceName={soilTemperature.name}
        lastSync={formatDateLastUpdated(soilTemperature.sync)}
      />
      <SensorReadingCard
        sensorName="PRITISAK U SISTEMU NAVODNJAVANJA"
        value={`${pressureInSystemStart.reading} bar`}
        icon={<Gauge className="h-10 w-10 mr-2 mt-1 text-[#2a9d8f]" />}
        deviceName={pressureInSystemStart.name}
        lastSync={formatDateLastUpdated(pressureInSystemStart.sync)}
      />
      <SensorReadingCard
        sensorName="PROTOK VODE START"
        value={`${flowMeterStart.reading} m3/h`}
        icon={<Droplet className="h-10 w-10 mr-2 mt-1 text-[#0077b6]" />}
        deviceName={flowMeterStart.name}
        lastSync={formatDateLastUpdated(flowMeterStart.sync)}
      />
    </div>
  );
};

const CollectionOfSensorReadingCardsForFertDevice: FC<CardGroupsFertProps> = ({
  response,
  device,
}) => {
  const [pHWater, setPHWater] = useState<CardData>({
    reading: 0,
    name: 'Uredjaj',
    sync: '15',
  });
  const [ec, setEc] = useState<CardData>({
    reading: 0,
    name: 'Uredjaj',
    sync: '15',
  });
  const [flowMeterFert, setFlowMeterFert] = useState<CardData>({
    reading: 0,
    name: 'Uredjaj',
    sync: '15',
  });
  const [waterLevel, setWaterLevel] = useState<CardData>({
    reading: 'Manje od 20%',
    name: 'Uredjaj',
    sync: '15',
  });
  const [pressureInSystemFert, setPressureInSystemFert] = useState<CardData>({
    reading: 0,
    name: 'Uredjaj',
    sync: '15',
  });

  useEffect(() => {
    if (!response) {
      return;
    }

    setFlowMeterFert({
      reading: response.flowMeterValue,
      name: device.name,
      sync: device.syncTime,
    });
    setPHWater({
      reading: response.pHWaterValue,
      name: device.name,
      sync: device.syncTime,
    });
    setEc({
      reading: response.ecSensorValue,
      name: device.name,
      sync: device.syncTime,
    });
    setPressureInSystemFert({
      reading: response.pressureInSystemValue,
      name: device.name,
      sync: device.syncTime,
    });

    if (response.waterLevelValue > 20 && response.waterLevelValue < 40) {
      setWaterLevel({
        reading: 'Više od 20%',
        name: device.name,
        sync: device.syncTime,
      });
    }

    if (response.waterLevelValue > 40 && response.waterLevelValue < 60) {
      setWaterLevel({
        reading: 'Više od 40%',
        name: device.name,
        sync: device.syncTime,
      });
    }

    if (response.waterLevelValue > 60 && response.waterLevelValue < 80) {
      setWaterLevel({
        reading: 'Više od 60%',
        name: device.name,
        sync: device.syncTime,
      });
    }

    if (response.waterLevelValue > 80) {
      setWaterLevel({
        reading: 'Više od 80%',
        name: device.name,
        sync: device.syncTime,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  return (
    <div className="flex flex-row gap-5 flex-wrap">
      <SensorReadingCard
        sensorName="EC VODE"
        value={`${ec.reading} mS/cm`}
        icon={<Waves className="h-10 w-10 mr-2 mt-1 text-[#0077b6]" />}
        deviceName={ec.name}
        lastSync={formatDateLastUpdated(ec.sync)}
      />
      <SensorReadingCard
        sensorName="PH VODE"
        value={`${pHWater.reading}`}
        icon={<TestTube className="h-10 w-10 mr-2 mt-1 text-[#0077b6]" />}
        deviceName={pHWater.name}
        lastSync={formatDateLastUpdated(pHWater.sync)}
      />
      <SensorReadingCard
        sensorName="NIVO VODE"
        value={`${waterLevel.reading}`}
        icon={<Waves className="h-10 w-10 mr-2 mt-1 text-[#0077b6]" />}
        deviceName={waterLevel.name}
        lastSync={formatDateLastUpdated(waterLevel.sync)}
      />
      <SensorReadingCard
        sensorName="PROTOK VODE FERT"
        value={`${flowMeterFert.reading} m3/h`}
        icon={<Droplet className="h-10 w-10 mr-2 mt-1 text-[#0077b6]" />}
        deviceName={flowMeterFert.name}
        lastSync={formatDateLastUpdated(flowMeterFert.sync)}
      />
      <SensorReadingCard
        sensorName="PRITISAK U SISTEMU FERT"
        value={`${pressureInSystemFert.reading} bar`}
        icon={<Gauge className="h-10 w-10 mr-2 mt-1 text-[#2a9d8f]" />}
        deviceName={pressureInSystemFert.name}
        lastSync={formatDateLastUpdated(pressureInSystemFert.sync)}
      />
    </div>
  );
};

const CollectionOfSensorReadingCardsForSensDevice: FC<CardGroupsSensProps> = ({
  response,
  device,
}) => {
  const [airHumidity, setAirHumidity] = useState<CardData>({
    reading: 0,
    name: 'Uredjaj',
    sync: '15',
  });
  const [airTemperature, setAirTemperature] = useState<CardData>({
    reading: 0,
    name: 'Uredjaj',
    sync: '15',
  });
  const [sunRadiation, setSunRadiation] = useState<CardData>({
    reading: 0,
    name: 'Uredjaj',
    sync: '15',
  });
  const [windSpeed, setWindSpeed] = useState<CardData>({
    reading: 0,
    name: 'Uredjaj',
    sync: '15',
  });
  const [flowMeterSens, setFlowMeterSens] = useState<CardData>({
    reading: 0,
    name: 'Uredjaj',
    sync: '15',
  });
  const [precipitationAmount, setPrecipitationAmount] = useState<CardData>({
    reading: 0,
    name: 'Uredjaj',
    sync: '15',
  });
  const [pHSoil, setPHSoil] = useState<CardData>({
    reading: 0,
    name: 'Uredjaj',
    sync: '15',
  });
  const [leafMoisture, setLeafMoisture] = useState<CardData>({
    reading: 0,
    name: 'Uredjaj',
    sync: '15',
  });
  const [pressureInSystemSens, setPressureInSystemSens] = useState<CardData>({
    reading: 0,
    name: 'Uredjaj',
    sync: '15',
  });

  useEffect(() => {
    if (!response) {
      return;
    }

    setPrecipitationAmount({
      reading: response.precipitationAmountValue,
      name: device.name,
      sync: device.syncTime,
    });
    setFlowMeterSens({
      reading: response.flowMeterValue,
      name: device.name,
      sync: device.syncTime,
    });
    setAirHumidity({
      reading: response.airHumidityValue,
      name: device.name,
      sync: device.syncTime,
    });
    setAirTemperature({
      reading: response.airTempValue,
      name: device.name,
      sync: device.syncTime,
    });
    setSunRadiation({
      reading: response.sunRadiationValue,
      name: device.name,
      sync: device.syncTime,
    });
    setWindSpeed({
      reading: response.windSpeedValue,
      name: device.name,
      sync: device.syncTime,
    });
    setPHSoil({
      reading: response.pHSoilValue,
      name: device.name,
      sync: device.syncTime,
    });
    setPressureInSystemSens({
      reading: response.pressureInSystemValue,
      name: device.name,
      sync: device.syncTime,
    });
    setLeafMoisture({
      reading: response.leafMoistureValue,
      name: device.name,
      sync: device.syncTime,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  return (
    <div className="flex flex-row gap-5 flex-wrap">
      <SensorReadingCard
        sensorName="VLAŽNOST VAZDUHA"
        value={`${airHumidity.reading}%`}
        icon={<Droplet className="h-10 w-10 mr-2 mt-1 text-[#00aaff]" />}
        deviceName={airHumidity.name}
        lastSync={formatDateLastUpdated(airHumidity.sync)}
      />
      <SensorReadingCard
        sensorName="TEMPERATURA VAZDUHA"
        value={`${airTemperature.reading} °C`}
        icon={<Thermometer className="h-10 w-10 mr-2 mt-1 text-[#f77f00]" />}
        deviceName={airTemperature.name}
        lastSync={formatDateLastUpdated(airTemperature.sync)}
      />
      <SensorReadingCard
        sensorName="BRZINA VETRA"
        value={`${windSpeed.reading} m/s`}
        icon={<Wind className="h-10 w-10 mr-2 mt-1 text-[#0077b6]" />}
        deviceName={windSpeed.name}
        lastSync={formatDateLastUpdated(windSpeed.sync)}
      />
      <SensorReadingCard
        sensorName="SUNČEVA RADIJACIJA"
        value={`${sunRadiation.reading} W/m2`}
        icon={<Sun className="h-10 w-10 mr-2 mt-1 text-[#ffb703]" />}
        deviceName={sunRadiation.name}
        lastSync={formatDateLastUpdated(sunRadiation.sync)}
      />
      <SensorReadingCard
        sensorName="PROTOK VODE SENS"
        value={`${flowMeterSens.reading} m3/h`}
        icon={<Droplet className="h-10 w-10 mr-2 mt-1 text-[#0077b6]" />}
        deviceName={flowMeterSens.name}
        lastSync={formatDateLastUpdated(flowMeterSens.sync)}
      />
      <SensorReadingCard
        sensorName="KOLIČINA PADAVINA"
        value={`${precipitationAmount.reading} L/24h`}
        icon={<CloudRain className="h-10 w-10 mr-2 mt-1 text-[#0077b6]" />}
        deviceName={precipitationAmount.name}
        lastSync={formatDateLastUpdated(precipitationAmount.sync)}
      />
      <SensorReadingCard
        sensorName="PRITISAK U SISTEMU SENS"
        value={`${pressureInSystemSens.reading} bar`}
        icon={<Gauge className="h-10 w-10 mr-2 mt-1 text-[#2a9d8f]" />}
        deviceName={pressureInSystemSens.name}
        lastSync={formatDateLastUpdated(pressureInSystemSens.sync)}
      />
      <SensorReadingCard
        sensorName="VLAŽNOST LISTA"
        value={`${leafMoisture.reading}%`}
        icon={<Leaf className="h-10 w-10 mr-2 mt-1 text-[#4caf50]" />}
        deviceName={leafMoisture.name}
        lastSync={formatDateLastUpdated(leafMoisture.sync)}
      />
      <SensorReadingCard
        sensorName="PH ZEMLJE"
        value={`${pHSoil.reading}`}
        icon={<TestTube className="h-10 w-10 mr-2 mt-1 text-[#8d6e63]" />}
        deviceName={pHSoil.name}
        lastSync={formatDateLastUpdated(pHSoil.sync)}
      />
    </div>
  );
};

const CollectionOfSensorReadingCards = () => {
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const [responseState, setResponseState] = useState<ZoneReadingResponse>();
  const zone = useAppSelector(getZone);
  const [device, setDevice] = useState<Device>(null);

  const handleErrorResponse = (
    response: ZoneReadingResponse | GetDeviceByIdResponse
  ) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  useEffect(() => {
    const fetchSensorReadings = async () => {
      if (!zone.id) {
        return;
      }

      const response = await dispatch(
        getSensorReadingsForZone(zone.id)
      ).unwrap();

      const responseDevice = await dispatch(
        getDeviceById(zone.deviceId)
      ).unwrap();

      if (!responseDevice.success) {
        handleErrorResponse(responseDevice);
      }

      if (!response.success) {
        handleErrorResponse(response);
      }

      setResponseState(response);
      setDevice(responseDevice.content);
    };

    fetchSensorReadings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, zone]);

  return (
    <Card>
      <CardHeader>
        <CardTitle>Podaci sa senzora</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex flex-row gap-5 max-md:flex-col flex-wrap">
          {device && device.type === DeviceType.SENS && (
            <CollectionOfSensorReadingCardsForSensDevice
              response={responseState.content as SensDevicesSensors}
              device={device}
            />
          )}
          {device && device.type === DeviceType.FERT && (
            <CollectionOfSensorReadingCardsForFertDevice
              response={responseState.content as FertDevicesSensors}
              device={device}
            />
          )}
          {device && device.type === DeviceType.START && (
            <CollectionOfSensorReadingCardsForStartDevice
              response={responseState.content as StartDevicesSensors}
              device={device}
            />
          )}
        </div>
      </CardContent>
    </Card>
  );
};
export default CollectionOfSensorReadingCards;
