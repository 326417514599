import { EditDeviceFormData } from '@/pages/EditDevicePage.tsx';
import { ErrorResponse } from '@/redux/auth/auth.types.ts';

export enum DeviceType {
  FERT = 'FERT',
  START = 'START',
  SENS = 'SENS',
}

export type DeviceForUserEdit = {
  id: string;
  name: string;
  type: DeviceType;
  color: string;
  batteryLevel: number;
  connectionLevel: number;
  numberOfZones: number;
};

export type GetDevicesForEditResponse = {
  success: boolean;
  message?: string;
  error?: ErrorResponse;
  content?: DeviceForUserEdit[];
};

export type GetDeviceByIdForEditResponse = {
  success: boolean;
  message?: string;
  error?: ErrorResponse;
  content?: EditDeviceFormData;
};

export type GetDeviceByIdResponse = {
  success: boolean;
  message?: string;
  error?: ErrorResponse;
  content?: Device;
};

export type Device = {
  name: string;
  syncTime: string;
  type: string;
};

export type UpdateDeviceForUserData = {
  id: string;
  name: string;
  color: string;
  parcelId: string;
};
