import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import ParcelInfoForm from '@/components/forms/parcel/ParcelInfoForm.tsx';
import CreateButtonSection from '@/components/forms/shared/CreateButtonSection.tsx';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion.tsx';
import { Form } from '@/components/ui/form.tsx';
import i18n from '@/i18n.ts';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { getBreadcrumbs } from '@/redux/breadcrumbs/breadcrumbs.selectors.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import { updateParcel } from '@/redux/parcel/parcel.actions.ts';
import {
  GetParcelForEditResponse,
  ParcelForUserEdit,
} from '@/redux/parcel/parcel.types.ts';
import {
  castStringToNumber,
  createEditParcelStartData,
  transformEditParcelData,
} from '@/utils/parcels.ts';
import { createEditParcelSchema } from '@/zod/types.ts';

export type EditParcelFormData = {
  id: string;
  name: string;
  numberOfZones: number;
  place: string;
  country: string;
  weatherForecast: string;
  durationOfTheActiveValve: string;
  timeBetweenValves: string;
};

type EditParcelFormProps = {
  existingParcel: ParcelForUserEdit;
};

const EditParcelForm: FC<EditParcelFormProps> = ({ existingParcel }) => {
  const locale = i18n.language;
  const [activeAccordion, setActiveAccordion] = useState<string>('infoForm');
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const breadcrumbs = useAppSelector(getBreadcrumbs);
  const { t } = useTranslation('translation');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const generateSchema = useCallback(() => createEditParcelSchema(), [locale]);

  const editForm = useForm({
    resolver: zodResolver(generateSchema()),
    defaultValues: { ...createEditParcelStartData() },
  });

  const handleErrorResponse = (response: GetParcelForEditResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onGiveUpClick = () => {
    const { link } = breadcrumbs[breadcrumbs.length - 2];
    router(link);
  };

  const onSubmit = async (data: EditParcelFormData) => {
    const transformedEditData = transformEditParcelData(
      data,
      existingParcel.additionalSensorsValues,
      existingParcel.basicSensorsValues
    );
    // @ts-ignore
    const response = await dispatch(updateParcel(transformedEditData)).unwrap();

    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    toast.success(t('parcelUpdated'));
    onGiveUpClick();
  };

  const handleErrorAccordion = () => {
    const { errors } = editForm.formState;

    if (Object.keys(errors).length === 0) {
      return;
    }

    if (
      errors.name ||
      errors.numberOfZones ||
      errors.place ||
      errors.country ||
      errors.weatherForecast
    ) {
      setActiveAccordion('infoForm');
    }
  };

  const checkIfNumberOfZonesLowerThanOriginal = () => {
    if (
      Number(editForm.getValues('numberOfZones')) < existingParcel.numberOfZones
    ) {
      editForm.setError('numberOfZones', { message: t('lowerNumberOfZones') });
      return true;
    }

    return false;
  };

  const handleFormSubmit = async () => {
    castStringToNumber(editForm);

    const isValid = await editForm.trigger();
    if (!isValid) {
      handleErrorAccordion();
      return;
    }

    if (checkIfNumberOfZonesLowerThanOriginal()) {
      handleErrorAccordion();
      return;
    }

    await editForm.handleSubmit(onSubmit)();
  };

  useEffect(() => {
    editForm.setValue('id', existingParcel.id);
    editForm.setValue('name', existingParcel.name);
    editForm.setValue('place', existingParcel.place);
    editForm.setValue('country', existingParcel.country);
    editForm.setValue('numberOfZones', existingParcel.numberOfZones);
    editForm.setValue('weatherForecast', existingParcel.weatherForecast);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingParcel]);

  useEffect(() => {
    const resetSchemaAndTriggerValidation = async () => {
      const isValid = await editForm.trigger();
      if (!isValid) {
        handleErrorAccordion();
        return;
      }

      if (checkIfNumberOfZonesLowerThanOriginal()) {
        handleErrorAccordion();
      }
    };

    if (existingParcel.id) {
      resetSchemaAndTriggerValidation();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  return (
    <Form
      reset={editForm.reset}
      formState={editForm.formState}
      clearErrors={editForm.clearErrors}
      control={editForm.control}
      getFieldState={editForm.getFieldState}
      getValues={editForm.getValues}
      handleSubmit={editForm.handleSubmit}
      register={editForm.register}
      resetField={editForm.resetField}
      setError={editForm.setError}
      setFocus={editForm.setFocus}
      setValue={editForm.setValue}
      trigger={editForm.trigger}
      unregister={editForm.unregister}
      watch={editForm.watch}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleFormSubmit();
        }}
      >
        <Accordion
          value={activeAccordion}
          onValueChange={(value) => setActiveAccordion(value || '')}
          type="single"
          collapsible
          className="w-full"
        >
          <AccordionItem value="infoForm">
            <AccordionTrigger>{t('basicParcelInfo')}</AccordionTrigger>
            <AccordionContent>
              <ParcelInfoForm form={editForm} />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
        <CreateButtonSection
          onGiveUpClick={onGiveUpClick}
          submitText={t('changeParcel')}
        />
      </form>
    </Form>
  );
};

export default EditParcelForm;
