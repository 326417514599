import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { Button } from '@/components/ui/button.tsx';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';
import { ChartConfig, ChartContainer } from '@/components/ui/chart.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import { getSelectedParcelId } from '@/redux/parcel/parcel.selectors.ts';
import {
  getTotalWaterConsumptionOfWaterForParcel,
  getZonesForParcel,
} from '@/redux/zone/zone.actions.ts';
import { getZone, getZones } from '@/redux/zone/zone.selectors.ts';
import {
  GetTotalWaterConsumptionResponse,
  GetZonesResponse,
  Zone,
} from '@/redux/zone/zone.types.ts';
import { filterDataWaterConsumption } from '@/utils/dateUtil.ts';

type TooltipProps = {
  visible: boolean;
  active?: boolean;
  payload?: any[];
};

const waterConsumptionChartConfig = {
  waterConsumption: {
    label: 'Potrosnja',
    color: 'hsl(var(--chart-1))',
  },
} satisfies ChartConfig;

const CustomTooltip: FC<TooltipProps> = ({ active, payload, visible }) => {
  const zones: Zone[] = useAppSelector(getZones);

  if (!visible && active && payload && payload.length) {
    const { date } = payload[0].payload;

    return (
      <div className="custom-tooltip bg-white p-3 rounded-lg shadow-lg">
        <p className="text-[#2b90d9] font-semibold">
          Potrošnja vode po zonama za dan {date}:
        </p>
        {zones.map((zone) => {
          const consumptionEntry = zone.waterConsumption.find(
            (entry) => entry.date === date
          );
          return consumptionEntry ? (
            <p key={zone.id} className="text-[#2b90d9]">
              {`${zone.name}: ${consumptionEntry.consumption} m3`}
            </p>
          ) : null;
        })}
      </div>
    );
  }

  if (active && payload && payload.length) {
    const { consumption, date } = payload[0].payload;

    return (
      <div className="custom-tooltip bg-white p-3 rounded-lg shadow-lg">
        <p className="text-[#2b90d9]">{`Potrošnja vode: ${consumption} m3`}</p>
        <p className="text-[#2b90d9]">{`Dan: ${date}`}</p>
      </div>
    );
  }

  return null;
};

const WaterConsumption: FC = () => {
  const zone = useAppSelector(getZone);
  const dispatch = useAppDispatch();
  const parcelId = useAppSelector(getSelectedParcelId);
  const router = useNavigate();
  const [daysByZone, setDaysByZone] = useState<number>(7);
  const [daysTotal, setDaysTotal] = useState<number>(7);
  const [filteredData, setFilteredData] = useState([]);
  const [visible, setVisible] = useState<boolean>(true);
  const [buttonText, setButtonText] = useState<string>('Ukupna potrošnja');
  const [zoneConsumption, setZoneConsumption] = useState([]);
  const [totalConsumption, setTotalConsumption] = useState([]);
  const [totalConsumptionForParcel, setTotalConsumptionForParcel] =
    useState<string>('0');
  const [averageConsumption, setAverageConsumption] = useState<string>('0');

  const handleErrorResponse = (
    response: GetZonesResponse | GetTotalWaterConsumptionResponse
  ) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const fetchTotalConsumption = async () => {
    if (!parcelId) {
      return;
    }

    const response = await dispatch(
      getTotalWaterConsumptionOfWaterForParcel(parcelId)
    ).unwrap();

    if (!response.success) {
      handleErrorResponse(response);

      return;
    }

    setTotalConsumption(response.content);
  };

  useEffect(() => {
    if (visible) {
      const {
        filteredDataWaterConsumption,
        totalConsumptionWaterConsumption,
        averageConsumptionWaterConsumption,
      } = filterDataWaterConsumption(zoneConsumption, 7);

      setDaysByZone(7);
      setFilteredData(filteredDataWaterConsumption);
      setTotalConsumptionForParcel(totalConsumptionWaterConsumption);
      setAverageConsumption(averageConsumptionWaterConsumption);

      return;
    }

    setDaysTotal(7);
    fetchTotalConsumption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    if (visible) {
      const {
        filteredDataWaterConsumption,
        totalConsumptionWaterConsumption,
        averageConsumptionWaterConsumption,
      } = filterDataWaterConsumption(zoneConsumption, daysByZone);

      setFilteredData(filteredDataWaterConsumption);
      setTotalConsumptionForParcel(totalConsumptionWaterConsumption);
      setAverageConsumption(averageConsumptionWaterConsumption);

      return;
    }

    const {
      filteredDataWaterConsumption,
      totalConsumptionWaterConsumption,
      averageConsumptionWaterConsumption,
    } = filterDataWaterConsumption(totalConsumption, daysTotal);

    setFilteredData(filteredDataWaterConsumption);
    setTotalConsumptionForParcel(totalConsumptionWaterConsumption);
    setAverageConsumption(averageConsumptionWaterConsumption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoneConsumption, totalConsumption, daysByZone, daysTotal]);

  useEffect(() => {
    const fetchZones = async () => {
      if (!parcelId) {
        return;
      }

      const response = await dispatch(getZonesForParcel(parcelId)).unwrap();

      if (!response.success) {
        handleErrorResponse(response);
      }
    };

    fetchZones();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, parcelId]);

  useEffect(() => {
    if (zone && zone.waterConsumption) {
      setZoneConsumption(zone.waterConsumption);
    }
  }, [zone]);

  return (
    <Card>
      <CardHeader>
        <CardTitle>Potrošnja vode</CardTitle>
        <div className="flex flex-row w-full xl:w-[50%] justify-between max-1700:flex-col">
          <div>
            <Button
              size="sm"
              className="h-10 gap-1 mt-[0.6rem] w-44 border rounded border-[#ccc] mr-2"
              onClick={() => {
                setVisible(!visible);
                setButtonText(
                  visible ? 'Potrošnja po zonama' : 'Ukupna potrošnja'
                );
              }}
            >
              <span>{buttonText}</span>
            </Button>
            <Button
              size="sm"
              className="h-10 gap-1 px-0  mt-[0.6rem] w-9 border rounded border-[#ccc]"
              onClick={() => (visible ? setDaysByZone(3) : setDaysTotal(3))}
            >
              <span>3D</span>
            </Button>
            <Button
              size="sm"
              className="h-10 gap-1 px-0  mt-[0.6rem] w-9 border rounded border-[#ccc]"
              onClick={() => (visible ? setDaysByZone(7) : setDaysTotal(7))}
            >
              <span>7D</span>
            </Button>
            <Button
              size="sm"
              className="h-10 gap-1 px-0  mt-[0.6rem] w-9 border rounded border-[#ccc]"
              onClick={() => (visible ? setDaysByZone(30) : setDaysTotal(30))}
            >
              <span>30D</span>
            </Button>
          </div>
        </div>
      </CardHeader>
      <CardContent>
        <div className="flex flex-row gap-5 max-md:flex-col">
          <div className="flex w-full xl:w-[50%]">
            <Card className="w-full">
              <CardHeader>
                <CardTitle>
                  Potrošnja vode (m3) - {totalConsumptionForParcel}
                </CardTitle>
              </CardHeader>
              <CardContent>
                <ChartContainer config={waterConsumptionChartConfig}>
                  <BarChart accessibilityLayer data={filteredData}>
                    <CartesianGrid vertical={false} />
                    <YAxis
                      type="number"
                      dataKey="consumption"
                      hide={false}
                      axisLine={false}
                    />
                    <XAxis
                      dataKey="date"
                      tickLine={false}
                      tickMargin={10}
                      axisLine={false}
                    />
                    <Tooltip
                      cursor={false}
                      content={<CustomTooltip visible={visible} />}
                    />
                    <Bar dataKey="consumption" fill="#2b90d9" radius={8} />
                    <ReferenceLine
                      y={Number(averageConsumption)}
                      stroke="red"
                      strokeDasharray="3 3"
                      label={{
                        value: `Prosek potrošnje: ${averageConsumption} m3`,
                        position: 'insideTopRight',
                        fill: 'red',
                      }}
                    />
                  </BarChart>
                </ChartContainer>
              </CardContent>
            </Card>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
export default WaterConsumption;
