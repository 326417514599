export type EditSensorFormData = {
  soilHumidity: boolean;
  airHumidity: boolean;
  airTemperature: boolean;
  soilTemperature: boolean;
  waterPH: boolean;
  soilPH: boolean;
  waterSalinity: boolean;
  soilSalinity: boolean;
  flowMeter: boolean;
  waterLevel: boolean;
  batteryLevel: boolean;
  supplementOne: boolean;
  supplementTwo: boolean;
  supplementThree: boolean;
  supplementFour: boolean;
  supplementFive: boolean;
  supplementMixer: boolean;
  pidRegulatorOne: boolean;
  pidRegulatorTwo: boolean;
  pidRegulatorThree: boolean;
  pidRegulatorFour: boolean;
  proportionalMode: boolean;
};

export const createEditSensorFormStartData = (): EditSensorFormData => {
  return {
    soilHumidity: false,
    airHumidity: false,
    airTemperature: false,
    soilTemperature: false,
    waterPH: false,
    soilPH: false,
    waterSalinity: false,
    soilSalinity: false,
    flowMeter: false,
    waterLevel: false,
    batteryLevel: false,
    supplementOne: false,
    supplementTwo: false,
    supplementThree: false,
    supplementFour: false,
    supplementFive: false,
    supplementMixer: false,
    pidRegulatorOne: false,
    pidRegulatorTwo: false,
    pidRegulatorThree: false,
    pidRegulatorFour: false,
    proportionalMode: false,
  };
};
