import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useFieldArray, useForm, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import CustomFormDateTimePicker from '@/components/controls/CustomFormDateTimePicker.tsx';
import CustomFormDropDown from '@/components/controls/CustomFormDropDown.tsx';
import CustomFormInput from '@/components/controls/CustomFormInput.tsx';
import MixerForm from '@/components/forms/irrigation/MixerForm.tsx';
import ShiftsFormSection from '@/components/forms/irrigation/ShiftsFormSection.tsx';
import SupplementForm from '@/components/forms/irrigation/SupplementForm.tsx';
import { Button } from '@/components/ui/button.tsx';
import { Checkbox } from '@/components/ui/checkbox.tsx';
import { Form } from '@/components/ui/form.tsx';
import i18n from '@/i18n.ts';
import { getBreadcrumbs } from '@/redux/breadcrumbs/breadcrumbs.selectors.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import { createIrrigation } from '@/redux/irrigation/irrigation.actions.ts';
import {
  CreateIrrigationResponse,
  Irrigation,
  Shift,
} from '@/redux/irrigation/irrigation.types.ts';
import { getSelectedParcelId } from '@/redux/parcel/parcel.selectors.ts';
import { createProgram } from '@/redux/program/program.actions.ts';
import { IrrigationType, irrigationTypes } from '@/utils/irrigation.utils.ts';
import { createIrrigationFormSchema } from '@/zod/types.ts';

export type IrrigationFormData = {
  parcelId: string;
  irrigationType: IrrigationType | '';
  dateAndTimeOfIrrigation: Date;
  workTimeOfIrrigation: number;
  shifts: Shift[];
  mixerEnabled: boolean;
  mixerStartDateTime: Date;
  mixerWorkTime: number;
  supplementOneEnabled: boolean;
  supplementOneDateTime: Date;
  supplementOneWorkTime: number;
  pH: number;
  supplementTwoEnabled: boolean;
  supplementTwoDateTime: Date;
  supplementTwoWorkTime: number;
  supplementTwoEC: number;
  supplementThreeEnabled: boolean;
  supplementThreeDateTime: Date;
  supplementThreeWorkTime: number;
  supplementThreeEC: number;
  supplementFourEnabled: boolean;
  supplementFourDateTime: Date;
  supplementFourWorkTime: number;
  supplementFourEC: number;
  programEnabled: boolean;
  programName?: string;
};

type SupplementFormSectionProps = {
  form: UseFormReturn<any>;
};

type ProgramFormData = {
  programName: string;
  irrigation: Irrigation;
};

const SupplementFormSection: FC<SupplementFormSectionProps> = ({ form }) => {
  return (
    <>
      <SupplementForm
        form={form}
        index={1}
        enableName="supplementOneEnabled"
        startDateName="supplementOneDateTime"
        workTimeName="supplementOneWorkTime"
        inputFieldName="pH"
        inputFieldLabelName="pH"
      />
      <SupplementForm
        form={form}
        index={2}
        enableName="supplementTwoEnabled"
        startDateName="supplementTwoDateTime"
        workTimeName="supplementTwoWorkTime"
        inputFieldName="supplementTwoEC"
        inputFieldLabelName="EC"
      />
      <SupplementForm
        form={form}
        index={3}
        enableName="supplementThreeEnabled"
        startDateName="supplementThreeDateTime"
        workTimeName="supplementThreeWorkTime"
        inputFieldName="supplementThreeEC"
        inputFieldLabelName="EC"
      />
      <SupplementForm
        form={form}
        index={4}
        enableName="supplementFourEnabled"
        startDateName="supplementFourDateTime"
        workTimeName="supplementFourWorkTime"
        inputFieldName="supplementFourEC"
        inputFieldLabelName="EC"
      />
    </>
  );
};

const CreateIrrigationForm: FC = () => {
  const locale = i18n.language;
  const parcelId = useAppSelector(getSelectedParcelId);
  const { t } = useTranslation('translation');
  const [isInM3, setIsInM3] = useState(false);
  const breadcrumbs = useAppSelector(getBreadcrumbs);

  const initialRender = useRef(true);
  const hasTriggered = useRef(false);
  const generateSchema = useCallback(
    () => createIrrigationFormSchema(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale]
  );
  const [savedProgramOption, setSavedProgramOption] = useState(false);

  const form = useForm<IrrigationFormData>({
    resolver: zodResolver(generateSchema()),
    defaultValues: {
      parcelId,
      irrigationType: IrrigationType.TIME,
      dateAndTimeOfIrrigation: new Date(),
      workTimeOfIrrigation: 0,
      shifts: [],
      mixerEnabled: false,
      mixerStartDateTime: new Date(),
      mixerWorkTime: 0,
      supplementOneEnabled: false,
      supplementOneDateTime: new Date(),
      supplementOneWorkTime: 0,
      pH: 0,
      supplementTwoEnabled: false,
      supplementTwoDateTime: new Date(),
      supplementTwoWorkTime: 0,
      supplementTwoEC: 0,
      supplementThreeEnabled: false,
      supplementThreeDateTime: new Date(),
      supplementThreeWorkTime: 0,
      supplementThreeEC: 0,
      supplementFourEnabled: false,
      supplementFourDateTime: new Date(),
      supplementFourWorkTime: 0,
      supplementFourEC: 0,
      programEnabled: false,
      programName: '',
    },
  });

  const { fields, append, remove } = useFieldArray<
    IrrigationFormData,
    'shifts'
  >({
    control: form.control,
    name: 'shifts',
  });
  const dispatch = useAppDispatch();
  const router = useNavigate();

  const onIrrigationTypeChange = (type: string) => {
    setIsInM3(type === IrrigationType.M3);
  };

  const saveProgram = async (
    data: IrrigationFormData,
    irrigationResponse: CreateIrrigationResponse
  ) => {
    const programData: ProgramFormData = {
      programName: data.programName,
      irrigation: irrigationResponse.content,
    };

    const programResponse = await dispatch(createProgram(programData)).unwrap();

    if (!programResponse.success) {
      toast.error(programResponse.error.message);
      return;
    }

    toast.success(t('successfullySavedProgram'));
    const { link } = breadcrumbs[breadcrumbs.length - 2];
    router(link);
  };

  const calculateWorkTimeOfIrrigation = (shifts: Shift[]): number => {
    if (!Array.isArray(shifts)) {
      return 0;
    }

    return shifts.reduce((total, shift) => {
      const workTime = shift.workTimeOfIrrigation ?? 0;
      return total + workTime;
    }, 0);
  };

  const onSubmit = async (data: IrrigationFormData) => {
    const updatedData = {
      ...data,
      workTimeOfIrrigation: calculateWorkTimeOfIrrigation(data.shifts),
    };
    const invalidShift = data.shifts.find((shift) => {
      const isM3 = data.irrigationType === IrrigationType.M3;
      const isTime = data.irrigationType === IrrigationType.TIME;

      if (isM3 && shift.waterLevel <= 0) {
        return true;
      }
      if (isTime && shift.workTimeOfIrrigation <= 0) {
        return true;
      }

      return false;
    });

    if (invalidShift) {
      toast.error(t('errorIrrigationInvalidShiftValue'));
      return;
    }
    // @ts-ignore
    const irrigationResponse = await dispatch(
      createIrrigation(updatedData)
    ).unwrap();
    if (!irrigationResponse.success) {
      toast.error(t('errorIrrigation'));
      return;
    }

    if (!savedProgramOption) {
      toast.success(t('successfulSchedule'));
      const { link } = breadcrumbs[breadcrumbs.length - 2];
      router(link);
      return;
    }

    await saveProgram(updatedData, irrigationResponse);
  };

  useEffect(() => {
    // @ts-ignore
    form.setValue('parcelId', parcelId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parcelId]);

  useEffect(() => {
    const resetSchemaAndTriggerValidation = async () => {
      if (initialRender.current) {
        initialRender.current = false;
        return;
      }
      await form.trigger();
    };

    const errors = Object.keys(form.formState.errors).length;
    if (hasTriggered.current && errors) {
      resetSchemaAndTriggerValidation();
      return;
    }

    if (hasTriggered.current) {
      form.clearErrors();
      return;
    }

    resetSchemaAndTriggerValidation();
    hasTriggered.current = true;

    form.setValue('shifts', [
      { shiftId: 1, zone: 0, waterLevel: 0, workTimeOfIrrigation: 0 },
    ]);
  }, [locale, form]);

  return (
    <Form
      reset={form.reset}
      formState={form.formState}
      clearErrors={form.clearErrors}
      control={form.control}
      getFieldState={form.getFieldState}
      getValues={form.getValues}
      handleSubmit={form.handleSubmit}
      register={form.register}
      resetField={form.resetField}
      setError={form.setError}
      setFocus={form.setFocus}
      setValue={form.setValue}
      trigger={form.trigger}
      unregister={form.unregister}
      watch={form.watch}
    >
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="bg-white w-full">
          <div className="border-b border-gray-900/10  px-5 text-sm">
            <div className="flex flex-col">
              <CustomFormDateTimePicker
                form={form}
                name="dateAndTimeOfIrrigation"
                labelText={t('dateAndTimeIrrigation')}
                formItemStyle="flex flex-col w-full pb-5 max-md:py-5 gap-2"
                labelStyle="max-md:w-full"
                buttonStyle="justify-start text-left font-normal bg-white py-3 px-2 flex w-[500px] max-md:mt-2 max-md:w-full"
              />
              <div className="flex flex-row max-md:flex-col w-full pb-5 max-md:py-5 gap-2 md:mt-5">
                <CustomFormDropDown
                  customForm={form}
                  name="irrigationType"
                  labelText={t('irrigationType')}
                  formItemStyle="w-[500px] flex flex-col gap-1 max-md:w-full"
                  labelStyle="max-md:w-full"
                  items={irrigationTypes}
                  onChangeFunction={onIrrigationTypeChange}
                />
              </div>
              <ShiftsFormSection
                form={form}
                fields={fields}
                append={append}
                remove={remove}
              />
              <MixerForm form={form} />
              {form.getValues('irrigationType') && !isInM3 && (
                <SupplementFormSection form={form} />
              )}
              <div className="flex flex-row max-md:flex-col w-full pb-5 max-md:py-5 gap-2 md:mt-5">
                <Checkbox
                  onCheckedChange={() => {
                    setSavedProgramOption(!savedProgramOption);
                    form.setValue(
                      'programEnabled',
                      !form.getValues('programEnabled')
                    );
                  }}
                  labelText={t('saveProgramQuestion')}
                />
              </div>
              <div className="flex flex-row max-md:flex-col w-full pb-5 max-md:py-5 gap-2 md:mt-5">
                {savedProgramOption && (
                  <CustomFormInput
                    customForm={form}
                    name="programName"
                    labelText={t('programName')}
                    formItemStyle="flex flex-col w-full lg:w-[20%] gap-1"
                    inputStyle="px-2 py-3"
                    labelStyle="w-full"
                    divStyle="w-full"
                    type="text"
                  />
                )}
              </div>
              <div className="flex flex-row max-md:flex-col w-full py-10">
                <div className="w-full max-md:w-full flex flex-row gap-5">
                  <Button
                    className="bg-[#33b35a] py-3 px-2 text-white"
                    type="submit"
                  >
                    {t('scheduleIrrigation')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Form>
  );
};

export default CreateIrrigationForm;
