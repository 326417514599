import { CircleHelp, Droplet, Home, PanelLeft } from 'lucide-react';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { Button } from '@/components/ui/button.tsx';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet.tsx';
import { resetBreadcrumbs } from '@/redux/breadcrumbs/breadcrumbs.slice.ts';
import { useAppDispatch } from '@/redux/hooks.ts';

type MobileNavigationLinkProps = {
  text: string;
  icon: ReactNode;
  link: string;
  isActive: boolean;
};

const MobileNavigationLink: FC<MobileNavigationLinkProps> = ({
  text,
  link,
  icon,
  isActive,
}) => {
  const dispatch = useAppDispatch();

  const onLinkClick = () => {
    dispatch(resetBreadcrumbs());
  };

  return (
    <Link
      to={link}
      className={`flex items-center gap-4 px-2.5 py-2 rounded-lg text-muted-foreground hover:text-foreground ${isActive ? 'bg-[#2f333e] text-white' : 'text-[#2f333e]'}`}
      onClick={onLinkClick}
    >
      {icon}
      {text}
    </Link>
  );
};

const MobileDrawerNavigation: FC = () => {
  const { t } = useTranslation('translation');
  const location = useLocation();
  const path = location.pathname;
  const [isHomeActive, setIsHomeActive] = useState(false);
  const [isIrrigationActive, setIsIrrigationActive] = useState(false);
  const [isContactFormActive, setIsContactFormActive] = useState(false);

  const isActive = (link: string) => {
    if (link === '/' && path === '/') {
      return true;
    }
    return link !== '/' && path.startsWith(link);
  };

  useEffect(() => {
    setIsHomeActive(isActive('/'));
    setIsIrrigationActive(isActive('/irrigation'));
    setIsContactFormActive(isActive('/contact-form'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button size="icon" variant="outline" className="sm:hidden">
          <PanelLeft className="h-5 w-5" />
        </Button>
      </SheetTrigger>
      <SheetContent side="left" className="sm:max-w-xs bg-white">
        <SheetTitle className="text-xl text-[#2f333e]">
          {t('navigation')}
        </SheetTitle>
        <SheetDescription />
        <nav className="grid gap-6 text-lg font-medium mt-10">
          <MobileNavigationLink
            text={t('home')}
            icon={
              <Home
                className={`h-5 w-5 ${isHomeActive ? 'text-white' : 'text-[#2f333e]'}`}
              />
            }
            link="/"
            isActive={isHomeActive}
          />
          <MobileNavigationLink
            text={t('irrigation')}
            icon={
              <Droplet
                className={`h-5 w-5 ${isIrrigationActive ? 'text-white' : 'text-[#2f333e]'}`}
              />
            }
            link="/irrigation"
            isActive={isIrrigationActive}
          />
          <MobileNavigationLink
            text="Podrška"
            icon={
              <CircleHelp
                className={`h-5 w-5 ${isContactFormActive ? 'text-white' : 'text-[#2f333e]'}`}
              />
            }
            link="/contact-form"
            isActive={isContactFormActive}
          />
        </nav>
      </SheetContent>
    </Sheet>
  );
};

export default MobileDrawerNavigation;
