import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/ui/button.tsx';

type CreateButtonSectionProps = {
  onGiveUpClick: () => void;
  submitText: string;
};

const CreateButtonSection: FC<CreateButtonSectionProps> = ({
  onGiveUpClick,
  submitText,
}) => {
  const { t } = useTranslation('translation');

  return (
    <div className="flex flex-row max-md:flex-col w-full py-10">
      <div className="max-md:w-full flex flex-row gap-5 flex-wrap">
        <Button
          className="bg-[#868e96] text-white py-3 px-2 w-32"
          onClick={onGiveUpClick}
          type="button"
        >
          {t('giveUp')}
        </Button>
        <Button
          className="bg-[#33b35a] py-3 px-2 text-white w-32"
          type="submit"
        >
          {submitText}
        </Button>
      </div>
    </div>
  );
};

export default CreateButtonSection;
