import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import { SendQuestionData } from '@/components/forms/SendQuestionForm.tsx';
import { SendQuestionResponse } from '@/redux/question/question.types.ts';
import axiosServer from '@/services/axios.service.ts';

const sendQuestion = createAsyncThunk<SendQuestionResponse, SendQuestionData>(
  'user/send-question',
  async (reqData) => {
    try {
      const axiosResponse = await axiosServer.post<SendQuestionResponse>(
        `/user/question/send`,
        reqData
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

export { sendQuestion };
