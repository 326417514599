import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import { setZone, setZones } from '@/redux/zone/zone.slice.ts';
import {
  GetSensorReadingsResponse,
  GetTotalWaterConsumptionResponse,
  GetZonesResponse,
} from '@/redux/zone/zone.types.ts';
import axiosServer from '@/services/axios.service.ts';

const getZonesForParcel = createAsyncThunk<GetZonesResponse, string>(
  'user/get-zones-by-parcel',
  async (reqData, { dispatch }) => {
    try {
      const axiosResponse = await axiosServer.get<GetZonesResponse>(
        `/user/zone/get-zones?parcelId=${reqData}`
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      if (
        axiosResponse.data.content &&
        axiosResponse.data.content.length === 0
      ) {
        dispatch(setZones([]));
      }

      if (axiosResponse.data.content && axiosResponse.data.content.length > 0) {
        dispatch(setZones(axiosResponse.data.content));
        dispatch(setZone(axiosResponse.data.content[0]));
      }

      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

const getTotalWaterConsumptionOfWaterForParcel = createAsyncThunk<
  GetTotalWaterConsumptionResponse,
  string
>('user/get-water-consumption-for-parcel', async (reqData) => {
  try {
    const axiosResponse =
      await axiosServer.get<GetTotalWaterConsumptionResponse>(
        `/user/zone/get-total-consumption-of-water?parcelId=${reqData}`
      );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

const getSensorReadingsForZone = createAsyncThunk<
  GetSensorReadingsResponse,
  string
>('user/get-sensor-readings', async (reqData) => {
  try {
    const axiosResponse = await axiosServer.get<GetSensorReadingsResponse>(
      `/user/zone/get-sensor-readings?zoneId=${reqData}`
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

export {
  getZonesForParcel,
  getTotalWaterConsumptionOfWaterForParcel,
  getSensorReadingsForZone,
};
