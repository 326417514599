import { Home, Droplet, CircleHelp } from 'lucide-react';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip.tsx';
import { resetBreadcrumbs } from '@/redux/breadcrumbs/breadcrumbs.slice.ts';
import { useAppDispatch } from '@/redux/hooks.ts';

type SidebarNavigationLinkProps = {
  text: string;
  icon: ReactNode;
  link: string;
};

const SidebarNavigationLink: FC<SidebarNavigationLinkProps> = ({
  text,
  link,
  icon,
}) => {
  const dispatch = useAppDispatch();

  const onLinkClick = () => {
    dispatch(resetBreadcrumbs());
  };

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Link
          to={link}
          className="flex items-center flex-row justify-center rounded-lg text-muted-foreground transition-colors hover:text-foreground gap-2 mt-2"
          onClick={onLinkClick}
        >
          {icon}
          <p className="text-white text-base">{text}</p>
        </Link>
      </TooltipTrigger>
      <TooltipContent side="right" className="hidden">
        {text}
      </TooltipContent>
    </Tooltip>
  );
};

const Sidebar: FC = () => {
  const { t } = useTranslation();

  return (
    <TooltipProvider>
      <aside className="inset-y-0 left-0 z-10 hidden md:min-w-48 md:w-48 sm:w-40 flex-col border-r bg-[#2f333e] sm:flex">
        <nav className="flex flex-col items-start gap-4 px-5 sm:py-5">
          <SidebarNavigationLink
            text={t('home')}
            icon={<Home className="h-5 w-5 text-white" />}
            link="/"
          />
          <SidebarNavigationLink
            text={t('irrigation')}
            icon={<Droplet className="h-5 w-5 text-white" />}
            link="/irrigation"
          />
          <SidebarNavigationLink
            text="Podrška"
            icon={<CircleHelp className="h-5 w-5 text-white" />}
            link="/contact-form"
          />
        </nav>
      </aside>
    </TooltipProvider>
  );
};

export default Sidebar;
