import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import CustomFormDropDown, {
  DropDownItems,
} from '@/components/controls/CustomFormDropDown.tsx';
import { Form } from '@/components/ui/form.tsx';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import { getZoneId, getZones } from '@/redux/zone/zone.selectors.ts';
import { setZone } from '@/redux/zone/zone.slice.ts';

type Props = {
  visible: boolean;
};

const SelectZoneForParcelForm: FC<Props> = ({ visible }) => {
  const zoneId = useAppSelector(getZoneId);
  const zones = useAppSelector(getZones);
  const dispatch = useAppDispatch();
  const zoneForm = useForm({
    defaultValues: {
      zoneId,
    },
  });
  const zoneDropdown: DropDownItems[] = zones.map((zone) => ({
    text: zone.name,
    value: zone.id,
  }));
  const onChangeFunction = (selectedZoneId: string) => {
    const selectedZone = zones.filter((zone) => zone.id === selectedZoneId)[0];

    dispatch(setZone(selectedZone));
  };

  useEffect(() => {
    zoneForm.setValue('zoneId', zoneId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoneId]);

  return (
    <div>
      {visible && (
        <Form
          reset={zoneForm.reset}
          formState={zoneForm.formState}
          clearErrors={zoneForm.clearErrors}
          control={zoneForm.control}
          getFieldState={zoneForm.getFieldState}
          getValues={zoneForm.getValues}
          handleSubmit={zoneForm.handleSubmit}
          register={zoneForm.register}
          resetField={zoneForm.resetField}
          setError={zoneForm.setError}
          setFocus={zoneForm.setFocus}
          setValue={zoneForm.setValue}
          trigger={zoneForm.trigger}
          unregister={zoneForm.unregister}
          watch={zoneForm.watch}
        >
          <form className="flex flex-col items-center justify-center md:w-[300px] lg:w-[400px] max-md:w-full">
            <CustomFormDropDown
              customForm={zoneForm}
              name="zoneId"
              labelText=""
              items={zoneDropdown}
              onChangeFunction={onChangeFunction}
              formItemStyle="flex flex-col w-full"
            />
          </form>
        </Form>
      )}
    </div>
  );
};

export default SelectZoneForParcelForm;
