import { useForm } from 'react-hook-form';

import CustomFormTextArea from '@/components/controls/CustomFormTextArea.tsx';
import { Button } from '@/components/ui/button.tsx';
import { Form } from '@/components/ui/form.tsx';
import { useAppDispatch } from '@/redux/hooks.ts';
import { sendQuestion } from '@/redux/question/question.actions.ts';

export type SendQuestionData = {
  question: string;
};

const SendQuestionForm = () => {
  const dispatch = useAppDispatch();

  const questionForm = useForm({
    defaultValues: {
      question: '',
    },
  });

  const onSubmit = async (data: SendQuestionData) => {
    // @ts-ignore
    await dispatch(sendQuestion(data)).unwrap();
    questionForm.reset();
  };

  return (
    <Form
      reset={questionForm.reset}
      formState={questionForm.formState}
      clearErrors={questionForm.clearErrors}
      control={questionForm.control}
      getFieldState={questionForm.getFieldState}
      getValues={questionForm.getValues}
      handleSubmit={questionForm.handleSubmit}
      register={questionForm.register}
      resetField={questionForm.resetField}
      setError={questionForm.setError}
      setFocus={questionForm.setFocus}
      setValue={questionForm.setValue}
      trigger={questionForm.trigger}
      unregister={questionForm.unregister}
      watch={questionForm.watch}
    >
      <form
        className="flex flex-col items-center justify-center"
        onSubmit={questionForm.handleSubmit(onSubmit)}
      >
        <CustomFormTextArea
          customForm={questionForm}
          name="question"
          labelText="Detaljan opis"
          formItemStyle="flex flex-col max-md:flex-col w-full"
          labelStyle=""
          divStyle="w-full"
          placeholder="Postavite pitanje ili prijavite problem"
          textareaStyle="rounded-lg focus-visible:border-[#28a745] focus-visible:ring-0 h-[200px]"
        />
        <div className="flex items-center justify-center">
          <Button
            className="bg-[#28a745] px-2 py-3 text-white min-w-[100px] text-sm mt-2"
            type="submit"
          >
            Pošalji
          </Button>
        </div>
      </form>
    </Form>
  );
};
export default SendQuestionForm;
